import { GetRequestConfig, PostRequestConfig } from './internal'

const endpoint = process.env.IDP_ENDPOINT

const headers = { accept: 'application/json' }

export const fusionAuthSignaturePublicKeys = (): GetRequestConfig => ({
  url: `${endpoint}/.well-known/jwks.json`,
  config: { headers },
})

type FusionauthVerifyCodeRequest = {
  code: string
}

export type FusionauthVerifyCodeResponse = {
  token: string
  refreshToken: string
  user: { email: string }
}

export const fusionauthVerifyCode = ({
  code,
}: FusionauthVerifyCodeRequest): PostRequestConfig => ({
  url: `${endpoint}/api/passwordless/login`,
  data: { code },
  config: { headers },
})

type FusionauthSocialSignOnStartRequest = {
  applicationId: string
  codeChallenge: string
  redirectUri: string
  identityProviderId: string
}

export const fusionAuthSocialSignOnStartUrl = ({
  applicationId,
  codeChallenge,
  redirectUri,
  identityProviderId,
}: FusionauthSocialSignOnStartRequest): string =>
  `${endpoint}/oauth2/authorize?client_id=${applicationId}&code_challenge=${codeChallenge}&code_challenge_method=S256&response_type=code&scope=offline_access&redirect_uri=${encodeURIComponent(
    redirectUri
  )}&idp_hint=${encodeURIComponent(identityProviderId)}`

type FusionauthSocialSignOnRequest = {
  applicationId: string
  codeVerifier: string
  code: string
  redirectUri: string
}

export type FusionauthSocialSignOnResponse = {
  access_token: string
  refresh_token: string
}

export const fusionauthSocialSignOn = ({
  applicationId,
  codeVerifier,
  code,
  redirectUri,
}: FusionauthSocialSignOnRequest): PostRequestConfig => ({
  url: `${endpoint}/oauth2/token`,
  data: new URLSearchParams({
    client_id: applicationId,
    code_verifier: codeVerifier,
    code,
    grant_type: 'authorization_code',
    redirect_uri: redirectUri,
  }),
  config: { headers },
})

type FusionauthRefreshRequest = {
  refreshToken: string
}

export type FusionauthRefreshResponse = {
  token: string
  refreshToken: string
}

export const fusionauthRefresh = ({
  refreshToken,
}: FusionauthRefreshRequest): PostRequestConfig => ({
  url: `${endpoint}/api/jwt/refresh`,
  data: { refreshToken },
  config: { headers },
})
