var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.type === 'internal' && !_vm.isMounted && _vm.to
      ? 'nuxt-link'
      : (_vm.type === 'internal' && _vm.isMounted && _vm.to) || _vm.type === 'external' || _vm.type === 'tel'
      ? 'a'
      : 'button',{tag:"component",staticClass:"bsg-button",class:[
    ("size-" + _vm.size),
    ("look-" + _vm.look),
    ("color-theme-" + _vm.colorTheme),
    _vm.aimless && 'aimless',
    _vm.loading && 'loading',
    _vm.iconPosition !== 'no-icon' &&
      _vm.look !== 'icon' &&
      ("icon-position-" + _vm.iconPosition) ],attrs:{"id":_vm.trackingId,"to":_vm.type === 'internal' && !_vm.isMounted && _vm.to
      ? _vm.createInternalLink(_vm.to)
      : undefined,"href":_vm.disabled
      ? undefined
      : _vm.type === 'external' || _vm.type === 'tel'
      ? _vm.to
      : _vm.type === 'internal' && _vm.to
      ? _vm.createInternalLink(_vm.to)
      : undefined,"target":_vm.type === 'external' && !_vm.target ? '_blank' : _vm.target,"disabled":_vm.disabled,"title":_vm.title ? _vm.title : undefined,"type":_vm.to === 'submit' ? 'submit' : undefined,"tabindex":_vm.aimless || _vm.loading ? -1 : undefined},on:{"click":_vm.callFunction}},[_c('div',{staticClass:"content"},[(_vm.look !== 'icon')?_c('div',{staticClass:"text"},[(_vm.loading)?_c('div',{staticClass:"loading-spinner"},[_c('div',{staticClass:"pending"})]):[_vm._v(_vm._s(_vm.text))]],2):_vm._e(),_vm._v(" "),(_vm.iconPosition !== 'no-icon' || _vm.look === 'icon')?_c('div',{staticClass:"icon"},[_c('BSGIcon',{attrs:{"name":_vm.icon,"title":_vm.title,"size":_vm.look === 'icon'
            ? _vm.size === 'default'
              ? 24
              : 32
            : _vm.size === 'default'
            ? 20
            : 24,"color":_vm.iconColor}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }