import { Plugin } from '@nuxt/types'
import { isFeatureEnabled } from '~/services/feature-toggle.server'

declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Vue {
    $isFeatureEnabled: typeof isFeatureEnabled
  }
}

declare module '@nuxt/types' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface NuxtAppOptions {
    $isFeatureEnabled: typeof isFeatureEnabled
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Context {
    $isFeatureEnabled: typeof isFeatureEnabled
  }
}

const featureToggle: Plugin = (_ctx, inject) => {
  inject('isFeatureEnabled', isFeatureEnabled)
}

export default featureToggle
