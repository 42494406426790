import { NuxtAppOptions } from '@nuxt/types'
import {
  ConfiguratorBar,
  ConfiguratorBungeeStrength,
  ConfiguratorColor,
  ConfiguratorConfiguration,
  ConfiguratorFrame,
  ConfiguratorLegs,
  ConfiguratorMat,
  ConfiguratorSize,
} from './config'
import {} from '~/store/cart'
import { ResponseType as Cart } from '~/graphql/cart/cart'
import {
  query,
  unwrapResponse,
  VariableType,
} from '~/graphql/configurator/part'
import { makeApolloRequest } from '~/services/apollo'

export type CartLineAttribute = {
  key: string
  value: string
}

export type CartLine = {
  quantity: number
  merchandiseId: string
  attributes: CartLineAttribute[]
}

export const isConfigValid = ({
  size,
  frame,
  bar,
  legs,
  region,
}: ConfiguratorConfiguration): boolean => {
  switch (region) {
    case 'US': {
      return (
        !(size === 100 && frame === 'stainless') &&
        !(size === 100 && bar === 'tbar') &&
        !(size === 125 && frame === 'stainless') &&
        !(size === 112 && frame === 'stainless')
        // && error weil screw default ist wird nicht angezeigt  !(size === 112 && frame === 'eps' && legs === 'screw')
      )
    }
    case 'EU':
    case 'UK': {
      return (
        !(size === 100 && frame === 'stainless') &&
        !(size === 100 && bar === 'tbar') &&
        !(size === 112 && frame === 'stainless') &&
        !(size === 125 && frame === 'stainless') &&
        !(size === 125 && frame === 'stainless' && legs === 'fold')
      )
    }
    case 'CH': {
      return (
        !(size === 100 && frame === 'stainless') &&
        !(size === 125 && frame === 'stainless') &&
        !(size === 112 && frame === 'stainless') &&
        !(size === 100 && bar === 'tbar')
      )
    }
    default: {
      return (
        !(size === 100 && frame === 'stainless') &&
        !(size === 125 && frame === 'stainless') &&
        !(size === 112 && frame === 'stainless') &&
        !(size === 100 && bar === 'tbar')
      )
    }
  }
}

export const getVariantIndexHandle = (
  config: ConfiguratorConfiguration
): string =>
  `bellicon-${config.size}-${config.frame}-${config.legs}-${config.bar}-${config.mat}-${config.bungeeStrength}`

export const getCartLineConfiguration = (
  line: Cart['lines'][0],
  variantSlug: string
): ConfiguratorConfiguration => {
  const handleSplit = variantSlug.split('-')
  const attrs = JSON.parse(line.attributes[0].value)
  return {
    size: parseInt(handleSplit[1]) as ConfiguratorSize,
    frame: handleSplit[2] as ConfiguratorFrame,
    legs: handleSplit[3] as ConfiguratorLegs,
    bar: handleSplit[4] as ConfiguratorBar,
    mat: handleSplit[5] as ConfiguratorMat,
    bungeeStrength: parseInt(handleSplit[6]) as ConfiguratorBungeeStrength,
    matColor: attrs.matColor,
    bungeeColors: attrs.bungeeColors,
    assembly: attrs.assembly,
    __uniqueId: attrs.__uniqueId,
  }
}

const getColorSku = (color: ConfiguratorColor) =>
  color === 'beige'
    ? 'BE'
    : color === 'black'
    ? 'BK'
    : color === 'white'
    ? 'WH'
    : color === 'blue'
    ? 'BU'
    : color === 'grey'
    ? 'GY'
    : color === 'green'
    ? 'NG'
    : color === 'orange'
    ? 'OR'
    : color === 'pink'
    ? 'PI'
    : color === 'red'
    ? 'RE'
    : // color === 'yellow ?'
      'YE'

export const getColorLabel = (color: ConfiguratorColor): string =>
  `MODULE_CONFIGURATOR_COLOR_${color.toUpperCase().replace('-', '_')}`

type PartSkus = {
  frame: string
  bungees: string[]
  mat: string
  tBar: string
  rubberCaps: string
}

export const getPartSkus = (config: ConfiguratorConfiguration): PartSkus => ({
  frame: `FTH${config.size}BK${config.legs === 'screw' ? 'SC' : 'FO'}`,
  bungees: config.bungeeColors.map(
    (color) =>
      `BRG${
        config.bungeeStrength === 1
          ? 'SO'
          : config.bungeeStrength === 2
          ? 'ME'
          : config.bungeeStrength === 3
          ? 'ST'
          : config.bungeeStrength === 4
          ? 'EX'
          : 'UL'
      }${getColorSku(color)}`
  ),
  mat: `M${config.mat === 'comfort' ? 'CO' : 'ST'}${config.size}BK${getColorSku(
    config.matColor
  )}`,
  tBar:
    config.bar === 'tbar' && config.frame === 'stainless'
      ? 'TBARSETSIMT'
      : config.bar === 'tbar' && config.frame === 'eps'
      ? 'TBARSETBKMT'
      : config.bar === 'support' && config.size === 100
      ? 'SHUNIVERSAL1SETBK'
      : config.bar === 'support'
      ? 'SHUNIVERSALBK'
      : config.bar === 'support2'
      ? 'SHUNIVERSAL2SETBK'
      : '-',
  rubberCaps: `RC${config.frame === 'eps' ? 'D' : 'L'}GY`,
})

export const getQuantityAvailable = async (
  apolloProvider: NuxtAppOptions['apolloProvider'],
  locale: string,
  partSkus: PartSkus
): Promise<number> => {
  const makeQuantityRequest = async (sku: string) =>
    (
      await makeApolloRequest(apolloProvider!, locale, unwrapResponse, {
        query,
        variables: {
          sku: `'${sku}'`,
        } as VariableType,
      })
    )?.find((res) => res.sku === sku)?.quantityAvailable ?? 0

  const quantities = []
  quantities.push(makeQuantityRequest(partSkus.frame))
  partSkus.bungees.forEach((sku) => quantities.push(makeQuantityRequest(sku)))
  quantities.push(makeQuantityRequest(partSkus.mat))
  if (partSkus.tBar !== '-') quantities.push(makeQuantityRequest(partSkus.tBar))
  quantities.push(makeQuantityRequest(partSkus.frame))

  return (await Promise.all(quantities)).reduce(
    (acc, q) => Math.min(acc!, q),
    Infinity
  )
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const makeConfiguratorCartLine = (
  component: Vue,
  id: string,
  config: ConfiguratorConfiguration,
  region: string
) => {
  const attributes = [
    {
      key: '__configuration',
      value: JSON.stringify({
        bungeeColors: config.bungeeColors,
        matColor: config.matColor,
        assembly: config.assembly,
        __uniqueId: config.__uniqueId,
      }),
    },
    {
      key: '__parts',
      value: JSON.stringify(getPartSkus(config)),
    },
    {
      key: String(component.$t('MODULE_CONFIGURATOR_ATTRIBUTE_SIZE')),
      value: String(component.$convert.display(config.size, 'cm')),
    },
    {
      key: String(component.$t('MODULE_CONFIGURATOR_ATTRIBUTE_FRAME')),
      value: String(
        component.$t(`MODULE_CONFIGURATOR_FRAME_${config.frame.toUpperCase()}`)
      ),
    },
    {
      key: String(component.$t('MODULE_CONFIGURATOR_ATTRIBUTE_LEGS')),
      value: String(
        component.$t(`MODULE_CONFIGURATOR_LEGS_${config.legs.toUpperCase()}`)
      ),
    },
    {
      key: String(component.$t('MODULE_CONFIGURATOR_ATTRIBUTE_BAR')),
      value: String(
        component.$t(`MODULE_CONFIGURATOR_BAR_${config.bar.toUpperCase()}`)
      ),
    },
    {
      key: String(component.$t('MODULE_CONFIGURATOR_ATTRIBUTE_MAT')),
      value: String(
        component.$t(`MODULE_CONFIGURATOR_MAT_${config.mat.toUpperCase()}`)
      ),
    },
    {
      key: String(component.$t('MODULE_CONFIGURATOR_ATTRIBUTE_BUNGEES')),
      value: String(
        component.$t(
          `MODULE_CONFIGURATOR_BUNGEE_STRENGTH_${config.bungeeStrength}`
        )
      ),
    },
    {
      key: String(component.$t('MODULE_CONFIGURATOR_ATTRIBUTE_COLORS')),
      value: String(
        `${component.$t('MODULE_CONFIGURATOR_ATTRIBUTE_MAT')} (${component.$t(
          getColorLabel(config.matColor)
        )})${'\n'}${component.$t(
          'MODULE_CONFIGURATOR_ATTRIBUTE_BUNGEES'
        )} (${config.bungeeColors
          .map((color) => component.$t(getColorLabel(color)))
          .sort()
          .join(', ')})`
      ),
    },
    {
      key: String(component.$t('MODULE_CONFIGURATOR_ATTRIBUTE_ASSEMBLY')),
      value: String(
        component.$t(
          `MODULE_CONFIGURATOR_ATTRIBUTE_ASSEMBLY_${config.assembly?.toUpperCase()}`
        )
      ),
    },
    {
      key: '__uniqueId',
      value: config?.__uniqueId || 'fall_back',
    },
  ]

  return {
    quantity: 1,
    merchandiseId: id,
    attributes,
  }
}
