import { render, staticRenderFns } from "./index.vue?vue&type=template&id=a4986ec2&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=a4986ec2&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4986ec2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PatternNavBar: require('/workspace/styleguide/patterns/NavBar.vue').default,PatternSubBar: require('/workspace/styleguide/patterns/SubBar.vue').default})
