var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pattern-nav-bar",class:[_vm.position, { 'color-theme-dark': _vm.colorTheme === 'dark' }]},[_c('div',{staticClass:"background background-menu",style:({
      opacity: _vm.isMobileLayer ? '1' : '0',
      transition: ("opacity 100ms ease-in-out" + (_vm.isMobileLayer ? ' 500ms' : '')),
    })}),_vm._v(" "),_c('div',{staticClass:"root z-0",class:{
      scrolled: _vm.showBackground && _vm.position === 'fixed',
      hideShadow: _vm.isMobileLayer,
    },style:({
      opacity: _vm.show ? '1' : '0',
      pointerEvents: _vm.show ? 'auto' : 'none',
    })},[_c('div',{staticClass:"container"},[_c('BSGLink',{staticClass:"logo-link",attrs:{"functional":"","to":_vm.linkToHome},on:{"click":_vm.hideMobileLayer}},[_c('BSGLogo',{attrs:{"negative":_vm.colorTheme === 'dark' && !_vm.showBackground && !_vm.isMobileLayer}})],1),_vm._v(" "),_c('div',{staticClass:"nav-links relative"},[_c('PatternNavBarLinks',{staticClass:"-lg:hidden",attrs:{"items":_vm.items,"layout":"desktop-root"}})],1),_vm._v(" "),_c('div',{staticClass:"right-group"},[_c('button',{ref:"hamburg",staticClass:"hamburg",attrs:{"aria-label":"menu"},on:{"click":_vm.toggleMobileLayer}},[_c('div',{staticClass:"content"},[_c('span',{staticClass:"line",class:_vm.colorTheme === 'dark' && !_vm.showBackground && !_vm.isMobileLayer
                  ? 'bg-neutral-000'
                  : 'bg-neutral-800'}),_vm._v(" "),_c('span',{staticClass:"line",class:_vm.colorTheme === 'dark' && !_vm.showBackground && !_vm.isMobileLayer
                  ? 'bg-neutral-000'
                  : 'bg-neutral-800'})])]),_vm._v(" "),(!_vm.isMobileLayer)?_c('div',{key:"cart",staticClass:"cart"},[_c('BSGLink',{staticClass:"cart-link",attrs:{"color-theme":_vm.colorTheme,"look":"icon","icon":"shopping-bag","text":"Cart","title":_vm.$t('CART'),"to":_vm.linkToCart}},[_c('BSGIcon',{attrs:{"name":"shopping-bag","size":24,"color":_vm.colorTheme === 'dark' && !_vm.showBackground
                  ? 'neutral-000'
                  : 'neutral-800'}}),_vm._v(" "),_c('transition',{attrs:{"name":_vm.animateCount,"mode":"out-in"}},[(_vm.cartCount > 0)?_c('span',{key:_vm.cartCount,staticClass:"count"},[_vm._v("\n                "+_vm._s(_vm.cartCount))]):_vm._e()])],1)],1):_c('div',{key:"lang"},[_c('BSGButton',{attrs:{"text":_vm.currentLanguage.toUpperCase(),"look":"outline","icon":"globe","icon-position":"left"},on:{"click":function($event){return _vm.$emit('change-locale')}}})],1)]),_vm._v(" "),(!_vm.isShop)?_c('BSGButton',{staticClass:"video",attrs:{"text":_vm.trainingText,"look":"primary","icon":"play-circle","icon-position":"left","color-theme":_vm.colorTheme === 'light' ? 'light' : _vm.showBackground ? 'light' : 'dark',"to":_vm.trainingTo}}):_vm._e()],1)]),_vm._v(" "),(_vm.isMobileLayer)?_c('div',{ref:"mobile-layer",staticClass:"container mobile-layer -z-20"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"mobile-layer-container z-10"},[_c('div',{staticClass:"mobile-layer-content"},[(_vm.$isFeatureEnabled('video'))?_c('BSGLink',{staticClass:"video-mobile",attrs:{"to":_vm.trainingTo}},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.trainingTextLong))]),_vm._v(" "),_c('div',{staticClass:"icon"},[_c('BSGIcon',{attrs:{"name":"arrow-right","size":20}})],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex flex-grow w-full mx-auto max-w-[280px] md:max-w-[340px]"},[_c('PatternNavBarLinks',{attrs:{"items":_vm.items},on:{"hide":_vm.hideMobileLayer}})],1),_vm._v(" "),_c('PatternNavBarFooter',{staticClass:"nav-footer",attrs:{"follow-text":_vm.followText,"social-links":_vm.footerLinksSocial}})],1)])]):_vm._e(),_vm._v(" "),_vm._t("account")],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"absolute inset-0 z-0"},[_c('div',{staticClass:"animated-menu-background"}),_vm._v(" "),_c('div',{staticClass:"animated-menu-background",staticStyle:{"opacity":"0.3"}})])}]

export { render, staticRenderFns }