import { Plugin } from '@nuxt/types'

type FormatDate = (
  date: Date | string,
  locale: string,
  monthAsString?: boolean
) => string

declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Vue {
    $formatDate: FormatDate
  }
}

declare module '@nuxt/types' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface NuxtAppOptions {
    $formatDate: FormatDate
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Context {
    $formatDate: FormatDate
  }
}

const dateFormatter: Plugin = ({ app }, inject) => {
  // Function to format date based on locale
  const formatDate = (
    date: Date | string,
    locale: string,
    monthAsString = false
  ): string => {
    const d = new Date(date)

    const day = String(d.getDate()).padStart(2, '0')
    const month = monthAsString
      ? d.toLocaleString(locale, { month: 'long' })
      : String(d.getMonth() + 1).padStart(2, '0')
    const year = d.getFullYear()
    switch (locale.toLocaleLowerCase()) {
      case 'en-us':
        return monthAsString
          ? `${month} ${day}, ${year}`
          : `${month}/${day}/${year}`
      case 'en-uk':
        return monthAsString
          ? `${day} ${month} ${year}`
          : `${day}/${month}/${year}`
      case 'de-eu':
        return monthAsString
          ? `${day}. ${month} ${year}`
          : `${day}.${month}.${year}`
      case 'fr-eu':
        return monthAsString
          ? `${day} ${month} ${year}`
          : `${day}/${month}/${year}`
      case 'nl-eu':
        return monthAsString
          ? `${day} ${month} ${year}`
          : `${day}-${month}-${year}`
      default:
        // catches en-eu, en-ch, de-ch, fr-ch
        return monthAsString
          ? `${day} ${month} ${year}`
          : `${day}.${month}.${year}`
    }
  }

  // Inject the function into the context as $formatDate
  inject('formatDate', formatDate)
}

export default dateFormatter
