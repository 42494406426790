import cookie from 'js-cookie'
import { Plugin } from '@nuxt/types'
import { trackEvent } from './../services/tracking'
import appConfig from '~/app.config'

type PartnerIds = {
  aid: string
  cid: string
}

type Tracking = {
  trackEvent: (
    locale: string,
    event: string,
    optionalFields: Record<string, string | number | string[] | number[]>
  ) => void
  getPartnerIds: () => PartnerIds
}

declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Vue {
    $tracking: Tracking
  }
}

declare module '@nuxt/types' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface NuxtAppOptions {
    $tracking: Tracking
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Context {
    $tracking: Tracking
  }
}

declare global {
  interface Navigator {
    brave?: {
      isBrave: () => Promise<boolean>;
    };
  }
}

// post affiliate pro
const PARTNER_CAMPAIGN_ID = '4df02f23'

const setPartnerIds = (urlParams: URLSearchParams) => {
  // Überprüfen, ob Marketing-Cookies erlaubt sind
  if( !navigator.brave ) {
    if (CookieConsent && CookieConsent.consent.marketing) {
      // Affiliate ID
      const aid = urlParams.get('acc');

      // Campaign ID (derzeit immer gleich)
      const cid = urlParams.get('campaign') || PARTNER_CAMPAIGN_ID;

      // Wenn vorhanden, Partner-IDs für Post Affiliate Pro speichern
      if (aid && cid) {
        cookie.set(appConfig.cookies.partner.name, JSON.stringify({ aid, cid }), {
          expires: appConfig.cookies.partner.lifetimeDays,
          secure: appConfig.cookies.secure,
        });
      }
    }
  }
};

const callbackPartner = () => {
  cookie.remove(appConfig.cookies.partner.name)
}

const getPartnerIds = (): PartnerIds | undefined => {
  const partnerIds = cookie.get(appConfig.cookies.partner.name)
  if (partnerIds) {
    const { aid, cid } = JSON.parse(partnerIds)
    return {
      aid,
      cid,
    }
  }
}

const tracking: Plugin = ({ app, i18n }, inject) => {
  // post affiliate pro
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  if (urlParams.get('acc') === 'callback') {
    callbackPartner()
  } else {
    setPartnerIds(urlParams)
    // Event-Listener: Consent wurde akzeptiert
    window.addEventListener(
      'CookiebotOnAccept',
      () => {
        setPartnerIds(urlParams)
        window.PostAffTracker.setAccountId('default1');
        window.PostAffTracker.setParamNameUserId('acc');
        try {
          window.PostAffTracker.track();
        } catch (err) {
        }
      },
      false
    );

  }

  app.router?.afterEach((_to) => {
    trackEvent(i18n.locale, 'PageView', {})
  })

  inject('tracking', {
    trackEvent,
    getPartnerIds,
  })
}

export default tracking
