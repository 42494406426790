



































































































































































import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'

import BSGButton from '../components/Button.vue'
import BSGLink from '../components/Link.vue'
import BSGIcon from '../components/Icon.vue'
import BSGLogo from '../components/Logo.vue'
import PatternNavBarFooter from './NavBarFooter.vue'
import PatternNavBarLinks from './NavBarLinks.vue'
import { enumValidator } from '~/services/enum-props'

const INITIAL_SHOW_SCROLL_THRESHOLD = 50
const SHOW_SCROLL_THRESHOLD = 50

export type NavBarItem = {
  name: TranslateResult
  to: string | object | (() => void) | NavBarItem[]
  slot?: string
}

export type Links = {
  name: TranslateResult
  to: string | object
  icon?: string
}

export default Vue.extend({
  name: 'PatternNavBar',
  components: {
    BSGButton,
    BSGLink,
    BSGIcon,
    BSGLogo,
    PatternNavBarFooter,
    PatternNavBarLinks,
  },
  props: {
    currentLanguage: {
      type: String as PropType<string>,
      required: true,
    },
    trainingText: {
      type: String as PropType<string>,
      required: true,
    },
    trainingTextLong: {
      type: String as PropType<string>,
      required: true,
    },
    trainingTo: {
      type: [String, Object, undefined] as PropType<
        string | object | undefined
      >,
      default: undefined,
    },
    cartCount: {
      type: Number as PropType<number>,
      default: 0,
    },
    footerLinks: {
      type: Array as PropType<Links[]>,
      required: true,
    },
    footerLinksSocial: {
      type: Array as PropType<Links[]>,
      required: true,
    },
    items: {
      type: Array as PropType<NavBarItem[]>,
      required: true,
    },
    linkToCart: {
      type: String as PropType<string>,
      required: true,
    },
    linkToHome: {
      type: String as PropType<string>,
      required: true,
    },
    followText: { type: String as PropType<string>, required: true },
    colorTheme: {
      type: String as PropType<string>,
      default: 'light',
      validator: enumValidator(['light', 'dark']),
    },
    position: {
      type: String as PropType<string>,
      default: 'fixed',
      validator: enumValidator(['fixed', 'absolute']),
    },
  },
  data() {
    return {
      animateCount: 'none',
      cartCountUpdatedBefore: false,
      hoverItemLayer: false,
      isMobileLayer: false,
      show: true,
      showBackground: false,
      appearScrollY: 0,
      disappearScrollY: INITIAL_SHOW_SCROLL_THRESHOLD,
      isShop: false, // Variable zur Überprüfung, ob wir uns auf einer Shop-Seite befinden
    }
  },
  watch: {
    '$route.path'() {
      this.checkRoute() // Route bei jeder Navigation prüfen
    },
    '$screen.lg'(newVal) {
      if (newVal) this.hideMobileLayer()
    },
    cartCount() {
      if (!this.cartCountUpdatedBefore) {
        this.cartCountUpdatedBefore = true
      } else {
        this.animateCount = 'count'
      }
    },
  },
  mounted() {
    this.checkRoute() // Route beim ersten Laden prüfen
    const hamburg = this.$refs.hamburg as HTMLElement
    hamburg.addEventListener('animationend', () =>
      hamburg.classList.remove('inactive')
    )
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
    enableBodyScroll(this.$el as HTMLElement)
  },
  methods: {
    checkRoute() {
      const keywords = [
        '/shop',
        '/store',
        '/winkel',
        '/boutique',
        'article',
        'produkt',
        'product',
        'konfigurator',
        'configurator',
        'configurateur',
        'warenkorb',
        'shopping-cart',
        'panier',
        'winkelwagen',
      ] // Füge hier weitere relevante Keywords hinzu
      this.isShop = keywords.some((keyword) =>
        this.$route.path.toLowerCase().includes(keyword)
      )
    },
    onScroll() {
      if (scrollY >= this.disappearScrollY) {
        this.showBackground = true
        this.appearScrollY = Math.max(scrollY - SHOW_SCROLL_THRESHOLD, 0)
      } else if (scrollY <= this.appearScrollY) {
        this.show = true
        if (scrollY > 0) {
          this.showBackground = true
        } else {
          this.showBackground = false
        }
      }
    },
    hideMobileLayer() {
      if (this.isMobileLayer) this.toggleMobileLayer()
    },
    resize() {
      this.hideMobileLayer()
    },
    toggleMobileLayer() {
      this.isMobileLayer = !this.isMobileLayer
      if (this.isMobileLayer)
        disableBodyScroll(this.$el as HTMLElement, {
          allowTouchMove: () => true,
        })
      else enableBodyScroll(this.$el as HTMLElement)
      ;(this.$refs.hamburg as HTMLElement).classList.remove(
        this.isMobileLayer ? 'inactive' : 'active'
      )
      ;(this.$refs.hamburg as HTMLElement).offsetWidth
      ;(this.$refs.hamburg as HTMLElement).classList.add(
        this.isMobileLayer ? 'active' : 'inactive'
      )
    },
  },
})
