


























































































import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'

import BSGAccordion from '../components/Accordion.vue'
import BSGAccordionItem from '../components/AccordionItem.vue'
import BSGButton from '../components/Button.vue'
import BSGLogo from '../components/Logo.vue'
import BSGParagraph from '../components/Paragraph.vue'
import PatternFooterGroup from './FooterGroup.vue'
import { enumValidator } from '~/services/enum-props'
import { getColorStyle } from '~/styleguide/utils'

export type FooterLink = {
  name: TranslateResult
  to: string | object | (() => void)
}

export type SocialLink = FooterLink & { icon: string }

export const lookMapping = {
  'light-blue': 'blue-050',
  'light-green': 'blue-050',
  'light-neutral': 'neutral-100',
  'light-orange': 'orange-050',
  'light-purple': 'purple-050',
  'light-red': 'red-050',
  'light-yellow': 'yellow-050',
  'dark-blue': 'blue-800',
  'dark-green': 'green-800',
  'dark-neutral': 'neutral-800',
  'dark-orange': 'orange-800',
  'dark-purple': 'purple-800',
  'dark-red': 'red-700',
  'dark-yellow': 'yellow-700',
}

export type Look = keyof typeof lookMapping

export default Vue.extend({
  name: 'PatternFooter',
  components: {
    BSGAccordion,
    BSGAccordionItem,
    BSGButton,
    BSGLogo,
    BSGParagraph,
    PatternFooterGroup,
  },
  props: {
    text: {
      type: String as PropType<string>,
      required: true,
    },
    look: {
      type: String as PropType<Look>,
      default: 'dark-neutral',
      validator: enumValidator(Object.keys(lookMapping)),
    },
    legalLinks: {
      type: Array as PropType<FooterLink[]>,
      required: true,
    },
    companyHeadline: {
      type: String as PropType<string>,
      required: true,
    },
    companyLinks: {
      type: Array as PropType<FooterLink[]>,
      required: true,
    },
    productHeadline: {
      type: String as PropType<string>,
      required: true,
    },
    productLinks: {
      type: Array as PropType<FooterLink[]>,
      required: true,
    },
    serviceHeadline: {
      type: String as PropType<string>,
      required: true,
    },
    serviceLinks: {
      type: Array as PropType<FooterLink[]>,
      required: true,
    },
    socialLinks: {
      type: Array as PropType<SocialLink[]>,
      required: true,
    },
    localeText: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      lookMapping,
    }
  },
  computed: {
    bgColorClass(): string {
      return this.lookMapping[this.look as Look]
    },
    isDark(): boolean {
      return this.look.startsWith('dark')
    },
    /* Use color value to prevent purging tailwind class */
    colorStyle(): Partial<CSSStyleDeclaration> {
      return getColorStyle(this.bgColorClass, 'background-color')
    },
  },
})
