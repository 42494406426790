














import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'CommonVideoNavbarItem',
  props: {
    to: {
      type: [String, Object] as PropType<string | object>,
      default: undefined,
    },
    text: {
      type: String as PropType<string>,
      required: true,
    },
    icon: {
      type: String as PropType<string>,
      required: true,
    },
  },
  computed: {
    isActive() {
      const current = this.$route.name?.split('___')[0] || ''
      if (
        this.to === 'video' &&
        this.$route.path === this.$routerI18n.localePath(this.to)
      )
        return true
      if (this.to === 'video-find' && current.startsWith('video-find'))
        return true
      if (this.to === 'video-account' && current.startsWith('video-account'))
        return true
      if (this.to === 'video-stars' && current.startsWith('video-stars'))
        return true
      return false
    },
  },
})
