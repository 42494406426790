






























import Vue, { PropType } from 'vue'
import { Links, NavBarItem } from 'styleguide//patterns/NavBar.vue'
import appConfig from '~/app.config'
import { slugToIso } from '~/services/i18n'

type SubBar = {
  name: string
  to: string | object
  buttonText: string
}

export default Vue.extend({
  name: 'CommonNavBar',
  props: {
    colorTheme: { type: String as PropType<string>, required: true },
    position: { type: String as PropType<string>, required: true },
  },
  data() {
    return {
      sticky: false,
    }
  },
  computed: {
    trainingTo(): string {
      return this.$vuex.state.auth.token ? 'video' : 'auth-sign-in'
    },
    cartCount(): number {
      return this.$vuex.getters['cart/count']
    },
    footerLinksSocial(): Links[] {
      const networks = this.$vuex.getters.socials
      return Object.keys(networks).map((network) => {
        return {
          name: `${network.charAt(0).toUpperCase()}${network.slice(1)}`,
          icon: `social-${network}`,
          to: networks[network],
        }
      })
    },
    footerLinks(): Links[] {
      return [
        {
          name: this.$t('LINK_COOKIE_SETTINGS'),
          to: () => false,
        },
        {
          name: this.$t('LINK_PRIVACY'),
          to: 'privacy-policy',
        },
        {
          name: this.$t('LINK_LEGAL'),
          to: () => false,
        },
        {
          name: this.$t('LINK_WITHDRAWAL'),
          to: 'right-of-withdrawal',
        },
        {
          name: this.$t('LINK_IMPRINT'),
          to: 'imprint',
        },
        {
          name: this.$t('LINK_TERMS'),
          to: 'terms-conditions',
        },
      ]
    },
    items(): NavBarItem[] {
      return [
        {
          name: this.$t('LINK_TRAMPOLINE'),
          to: 'trampoline',
        },
        {
          name: this.$t('LINK_PLATFORM'),
          to: 'platform',
        },
        {
          name: this.$t('LINK_BENEFITS'),
          to: 'benefits',
        },
        {
          name: this.$t('LINK_CONSULTING'),
          to: 'consulting',
        },
        {
          name: this.$t('LINK_MAGAZINE'),
          to: 'magazine',
        },
        {
          name: this.$t('LINK_SHOP'),
          to: 'shop',
        },
      ].filter(
        (link) =>
          !appConfig.urlIsoBlacklist.some(
            ({ page, iso }) =>
              link.to === page.replace(/\/index$/, '') &&
              slugToIso(this.$i18n.locale) === iso
          )
      )
    },
    subBar(): undefined | SubBar {
      // test === page where the SubBar is shown
      const pagesWithSubBar = {
        test: {
          name: 'Trampoline Text',
          to: 'cart',
          buttonText: 'Buy',
        },
      }
      let currentSubBar
      Object.keys(pagesWithSubBar).some((key: string) =>
        this.$route.name?.startsWith(key) ? (currentSubBar = key) : undefined
      )
      return currentSubBar ? pagesWithSubBar[currentSubBar] : undefined
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll() {
      if (window.pageYOffset > 52) {
        this.sticky = true
      } else {
        this.sticky = false
      }
    },
  },
})
