var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"bsg-accordion-item",class:[
    _vm.options.colorTheme,
    { expanded: _vm.isExpanded, colored: _vm.color || _vm.options.color } ]},[_c('button',{style:(_vm.bgColorStyle),attrs:{"id":("accordion-id-" + _vm.id),"aria-expanded":_vm.isExpanded ? 'true' : 'false',"aria-controls":("sect" + _vm.id),"disabled":_vm.disabled},on:{"click":_vm.toggle}},[(_vm.icon || _vm.options.icon)?_c('BSGIcon',{style:(_vm.colorStyle),attrs:{"name":_vm.icon || _vm.options.icon,"size":40,"color":!_vm.isExpanded && (_vm.color || _vm.options.color)
          ? _vm.color || _vm.options.color
          : _vm.options.colorTheme === 'dark'
          ? 'neutral-000'
          : 'neutral-800'}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"name",style:(_vm.colorStyle)},[_vm._v("\n      "+_vm._s(_vm.name)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"toggle"},[_c('BSGIcon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isExpanded),expression:"!isExpanded"}],attrs:{"name":"angle-down","size":32,"color":!_vm.isExpanded && (_vm.color || _vm.options.color)
            ? 'neutral-800'
            : _vm.options.colorTheme === 'dark'
            ? 'neutral-000'
            : 'neutral-800'}}),_vm._v(" "),_c('BSGIcon',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExpanded),expression:"isExpanded"}],attrs:{"name":"angle-up","size":32,"color":_vm.options.colorTheme === 'dark' ? 'neutral-000' : 'neutral-800'}})],1)],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExpanded && !_vm.disabled),expression:"isExpanded && !disabled"}],staticClass:"content",attrs:{"id":("sect" + _vm.id),"role":"region","aria-labelledby":("accordion-id-" + _vm.id)}},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }