// eslint-disable-next-line
const plugin = require('tailwindcss/plugin')
module.exports = plugin(function ({ addComponents }) {
  addComponents({
    '.container': {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '100%',
      paddingLeft: '24px',
      paddingRight: '24px',
      '@screen md': {
        paddingLeft: '32px',
        paddingRight: '32px',
        maxWidth: '768px',
      },
      '@screen lg': {
        paddingLeft: '32px',
        paddingRight: '32px',
        maxWidth: '1024px',
      },
      '@screen xl': {
        paddingLeft: '32px',
        paddingRight: '32px',
        maxWidth: '1280px',
      },
    },
  })
})
