



























import Vue, { PropType } from 'vue'
import { enumValidator } from '~/services/enum-props'

export default Vue.extend({
  name: 'BSGLink',
  props: {
    /**
     * describes the target action, use
     * - a string or object (NuxtLink) for internal link
     * - an url beginning with "http" for external link
     */
    to: {
      type: [String, Object, undefined] as PropType<
        string | object | undefined
      >,
      default: undefined,
    },
    title: {
      type: String as PropType<string>,
      default: '',
    },
    functional: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    target: {
      type: [String] as PropType<string | undefined>,
      default: undefined,
      validator: undefined || enumValidator(['_self', '_blank']),
    },
    trackingId: {
      type: String as PropType<string>,
      default: '',
    },
  },
  data() {
    return {
      isMounted: false,
    }
  },
  computed: {
    type(): 'internal' | 'external' {
      if (typeof this.to === 'string' && this.to.startsWith('http')) {
        return 'external'
      }
      return 'internal'
    },
  },
  mounted() {
    this.isMounted = true
  },
  methods: {
    callFunction(event: MouseEvent) {
      if (this.type !== 'external') event.preventDefault()
      if (this.type === 'internal' && this.isMounted && this.to)
        this.$router.push(this.createInternalLink(this.to))
      this.$emit('click')
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createInternalLink(to: any) {
      // is $i18n library available
      if (this.$i18n && this.$routerI18n) {
        return this.$routerI18n.localePath(to)
      } else {
        return to
      }
    },
  },
})
