import { enumOptions } from '~/services/enum-props'
import tw from '~/tailwind.config'

const getPluginData = (pluginName: 'typography' | 'gradients') => {
  const plugins = tw.plugins as {
    config: { theme: { typography: Function; gradients: Function } }
  }[]
  const plugin = plugins.find(
    (plugin) => typeof plugin.config.theme[pluginName] === 'function'
  )
  return plugin!.config.theme[pluginName]()
}

export const colors = tw.theme.colors as unknown as Record<
  string,
  Record<string, string>
>

export const gradients = getPluginData('gradients') as unknown as Record<
  string,
  Record<string, Record<string, string>>
>

export const getColorNames = (): string[] => {
  const colorValues = Object.keys(colors).reduce(
    (acc: string[], group: string): string[] => {
      if (typeof colors[group] !== 'string') {
        Object.keys(colors[group]).forEach((key: string) => {
          acc.push(`${group}-${key}`)
        })
      }
      return acc
    },
    []
  )

  const sortedColors = colorValues.sort((a: string, b: string) => {
    if (a > b) {
      return -1
    }
    return 1
  })

  return sortedColors
}

export const getColorStyle = (
  colorClass: string,
  property?: string
): Partial<CSSStyleDeclaration> => {
  const [group, value] = colorClass.split('-')
  return { [property ? property : 'color']: colors[group][value] }
}

export const getIconNames = (): string[] => {
  const icons: string[] = []
  const context = require.context('~/assets/svg/icons/', true, /\.svg$/)
  context.keys().forEach((key: any) => {
    icons.push(key.substring(2, key.length - 4))
  })
  return icons
}

export const getFlagCodes = (): string[] => {
  const flags: string[] = []
  const context = require.context('~/assets/svg/flags/', true, /\.svg$/)
  context.keys().forEach((key: any) => {
    flags.push(key.substring(2, key.length - 4))
  })
  return flags
}

export const getIllustrationNames = (): string[] => {
  const icons: string[] = []
  const context = require.context('~/assets/svg/illustrations/', true, /\.svg$/)
  context.keys().forEach((key: any) => {
    icons.push(key.substring(2, key.length - 4))
  })
  return icons
}

export const getSkeletonNames = (): string[] => {
  const icons: string[] = []
  const context = require.context('~/assets/svg/skeletons/', true, /\.svg$/)
  context.keys().forEach((key: any) => {
    icons.push(key.substring(2, key.length - 4))
  })
  return icons
}

export const getTextClasses = (group: 'head' | 'text'): string[] => {
  const classes: string[] = []
  const typography = getPluginData('typography')
  if (group === 'head') {
    Object.keys(typography[group]).forEach((size) =>
      classes.push(`${group}-${size}`)
    )
  } else if (group === 'text') {
    Object.keys(typography[group]).forEach((size) =>
      Object.keys(typography[group][size]).forEach((decoration) =>
        classes.push(`${group}-${size}-${decoration}`)
      )
    )
  }
  return classes
}

export const getGradientClasses = (type: 'bg' | 'text'): string[] => {
  const classes: string[] = []
  const prefix = `${type}-gradient`
  const gradientType = gradients[prefix]
  Object.keys(gradientType).forEach((name) =>
    Object.keys(gradientType[name]).forEach((value) =>
      classes.push(`${prefix}-${name}-${value}`)
    )
  )

  return classes
}

export const getGradientStyle = (
  gradientClass: string
): Partial<CSSStyleDeclaration> => {
  const [type, plugin, name, value] = gradientClass.split('-')
  return gradients[`${type}-${plugin}`][name][value]
}

type StoryConfig = { [key: string]: any }

export const storyAddEnumSelectors = (story: StoryConfig) => {
  try {
    const props = story.component.options.props
    if (!props) return story
    const propNames = Object.keys(props).filter(
      (propName) => props[propName]?.validator?.enumOptions
    )
    if (!story.args) story.args = {}
    if (!story.argTypes) story.argTypes = {}
    propNames.forEach((propName) => {
      if (!(propName in story.args))
        story.args[propName] = props[propName].default
      if (!(propName in story.argTypes))
        story.argTypes[propName] = {
          options: enumOptions(story.component, propName),
          control: {
            type: 'select',
          },
        }
    })
  } catch (e) {
    console.error(e)
  }
  return story as StoryConfig
}

export const colorThemeTemplate = (
  story: string,
  always: 'light' | 'dark' | null = null
) => `
  <div
    :class="{\'bg-neutral-800\': ${
      always === 'light'
        ? 'false'
        : always === 'dark'
        ? 'true'
        : "$props.colorTheme === 'dark'"
    }}"
    class="absolute inset-0 flex justify-center items-center"
  >
    ${story}
  </div>
`

export const GridTemplate = (
  story: string,
  full?: boolean,
  overflow?: boolean
) => `
  <div
    :class="{\'container\': ${!full}, \'overflow-hidden\': ${!overflow}, \'overflow-visible\': ${overflow}}"
    class="grid-4 md:grid-8 xl:grid-12 py-20"
  >
    ${story}
  </div>
`

export const sleep = async (ms: number) => new Promise((_) => setTimeout(_, ms))
