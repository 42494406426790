// eslint-disable-next-line import/named
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import Vue from 'vue'

export type GetRequestConfig = {
  url: string
  config: Parameters<NuxtAxiosInstance['$request']>[0]
}

export type PostRequestConfig = {
  url: string
  data: Record<string, unknown> | URLSearchParams
  config: Parameters<NuxtAxiosInstance['$request']>[0]
}

export type PatchRequestConfig = {
  url: string
  data: Record<string, unknown> | URLSearchParams
  config: Parameters<NuxtAxiosInstance['$request']>[0]
}

export type DeleteRequestConfig = {
  url: string
  data?: Record<string, unknown> | URLSearchParams
  config: Parameters<NuxtAxiosInstance['$request']>[0]
}

export type AuthenticatedRequest = {
  token: string
}

type ApiResponse<T> = {
  status: number
  headers: Record<string, string>
  data: T
}

const errorCodesTriggeringRefresh = [400, 401, 403]

export const tryAuthenticatedWithRefresh =
  <RequestType, ResponseType>(
    template: (
      r: RequestType & AuthenticatedRequest
    ) => PostRequestConfig | GetRequestConfig | DeleteRequestConfig,
    method: 'post' | 'get' | 'delete' | 'patch'
  ) =>
  async (
    self: Vue,
    request: RequestType
  ): Promise<ApiResponse<ResponseType>> => {
    await new Promise<void>((resolve) => {
      if (!self.$vuex.state.auth.isLoading) resolve()
      self.$watch('$vuex.state.auth.isLoading', (newVal, oldVal) => {
        if (oldVal && !newVal) {
          resolve()
        }
      })
    })
    if (!self.$vuex.state.auth.token) throw { type: 'no-login' } // eslint-disable-line no-throw-literal
    const attemptRequest = async (templateRequest: PostRequestConfig) => {
      if (method === 'post')
        return await self.$axios.post(
          templateRequest.url,
          (templateRequest as PostRequestConfig).data,
          templateRequest.config
        )
      else if (method === 'patch')
        return await self.$axios.patch(
          templateRequest.url,
          (templateRequest as PatchRequestConfig).data,
          templateRequest.config
        )
      else if (method === 'get')
        return await self.$axios.get(
          templateRequest.url,
          templateRequest.config
        )
      else
        return await self.$axios.delete(
          templateRequest.url,
          templateRequest.config
        )
    }
    try {
      const templateRequest = template({
        ...request,
        token: self.$vuex.state.auth.token,
      })
      return await attemptRequest(templateRequest as PostRequestConfig)
    } catch (e) {
      const status =
        (e as { response?: { status: number } }).response?.status ?? -1
      if (errorCodesTriggeringRefresh.includes(status)) {
        if (!self.$vuex.state.auth.refreshToken) throw { type: 'token-expired' } // eslint-disable-line no-throw-literal
        try {
          await self.$vuex.dispatch('auth/refresh', {
            refreshToken: self.$vuex.state.auth.refreshToken,
          })
          const templateRequest = template({
            ...request,
            token: self.$vuex.state.auth.token,
          })
          return await attemptRequest(templateRequest as PostRequestConfig)
        } catch {
          throw { type: 'refresh-expired' } // eslint-disable-line no-throw-literal
        }
      }
    }
    throw { type: 'unknown' } // eslint-disable-line no-throw-literal
  }
