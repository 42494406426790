































import Vue, { PropType } from 'vue'
import { isoToSlug, makeIso, userLanguageFromRegion } from '~/services/i18n'

export default Vue.extend({
  name: 'CommonGeolocationSwitcher',
  props: {
    open: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    region: { type: String as PropType<string>, required: true },
  },
  computed: {
    language(): string {
      return userLanguageFromRegion(this.region)
    },
  },
  methods: {
    async changeLocale() {
      const iso = makeIso(this.region, this.language)
      const userSlug = isoToSlug(iso)
      await this.$vuex.commit('INIT_LOCALE_SSR', userSlug)
      await this.$vuex.dispatch('cart/get')
      await this.$router.push(this.localePath(this.$route.path, userSlug))
      this.$emit('change', false)
    },
  },
})
