import * as Vuex from 'vuex'
import { Plugin } from '@nuxt/types'
import { RootState } from '~/store'

/**
 * Nuxt 2 uses Vuex 3, so we can't overwrite $store types
 * @see https://github.com/vuejs/vuex/issues/994
 *
 * As workaround, we use $vuex in the application to get the typed store
 */

declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Vue {
    $vuex: Vuex.Store<RootState>
  }
}

declare module '@nuxt/types' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface NuxtAppOptions {
    $vuex: Vuex.Store<RootState>
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Context {
    $vuex: Vuex.Store<RootState>
  }
}

const vuexPlugin: Plugin = (context, inject) => {
  inject('vuex', context.store)
}

export default vuexPlugin
