











































import Vue, { PropType } from 'vue'
import BSGButton from '../components/Button.vue'
import BSGIcon from '../components/Icon.vue'
import BSGLink from '../components/Link.vue'
import { enumValidator } from '~/services/enum-props'

export type Link = {
  name: string
  to: string | object | (() => void)
  icon?: string
}

export default Vue.extend({
  name: 'PatternFooterGroup',
  components: {
    BSGButton,
    BSGIcon,
    BSGLink,
  },
  props: {
    dark: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    look: {
      type: String as PropType<string>,
      default: 'default',
      validator: enumValidator(['default', 'legal']),
    },
    links: {
      type: Array as PropType<Link[]>,
      required: true,
    },
    headline: {
      type: String as PropType<string>,
      default: '',
    },
    localeText: {
      type: String as PropType<string>,
      default: '',
    },
  },
})
