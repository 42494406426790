



















import Vue, { PropType } from 'vue'
import { enumValidator } from '~/services/enum-props'
import { getColorNames, getColorStyle } from '~/styleguide/utils'

export default Vue.extend({
  name: 'BSGIllustration',
  props: {
    name: {
      type: String as PropType<string>,
      required: true,
    },
    title: {
      type: String as PropType<string>,
      default: '',
    },
    borderColor: {
      type: String as PropType<string>,
      default: 'neutral-800',
      validator: enumValidator(getColorNames()),
    },
    bgColor: {
      type: String as PropType<string>,
      default: 'neutral-400',
      validator: enumValidator(getColorNames()),
    },
    fillColor: {
      type: String as PropType<string>,
      default: 'neutral-200',
      validator: enumValidator(getColorNames()),
    },
  },
  data() {
    return {
      illustration: undefined as undefined | Promise<unknown>,
    }
  },
  watch: {
    name: {
      immediate: true,
      async handler(name: string) {
        try {
          const { default: illustration } = await import(
            '~/assets/svg/illustrations/' + name + '.svg?inline'
          )
          this.illustration = illustration
        } catch {
          // Icon import failed
        }
      },
    },
  },
  methods: {
    getColorStyle,
  },
})
