import config, { Region } from './../app.config'

export const defaultRegion = config.defaultRegion
export const regions = config.regions
  .map((region) => region.id)
  .filter((regionId) => regionId !== 'UK')
export const regionsToDefaultLanguages = config.regions.reduce(
  (acc, region) => {
    acc[region.id] = region.defaultLanguage
    return acc
  },
  {} as {
    [region: Region['id']]: string
  }
)
export const regionsToLanguages = config.regions.reduce(
  (acc, region) => {
    acc[region.id] = region.languages
    return acc
  },
  {} as {
    [region: Region['id']]: Region['languages']
  }
)

const ISO_SEPARATOR = '-'

export const isValidLocale = (locale: string): boolean => {
  if (locale.includes(ISO_SEPARATOR)) {
    const [language, region] = locale.split(ISO_SEPARATOR)
    return (
      regions.includes(region) &&
      !!regionsToLanguages[region].find((l) => l === language)
    )
  } else {
    return false
  }
}

export const isoToSlug = (iso: string): string => iso.toLowerCase()

export const isoKeysToSlugs = <T>(o: Record<string, T>): Record<string, T> =>
  Object.fromEntries(
    Object.entries(o).map(([key, val]) => [isoToSlug(key), val])
  )

export const slugToIso = (slug: string): string =>
  slug
    .split('-')
    .map((c: string, i: number) => (i === 1 ? c.toUpperCase() : c))
    .join('-')

export const makeIso = (region: string | undefined, language: string): string =>
  `${language}${region ? ISO_SEPARATOR : ''}${region ?? ''}`

const isoToFullLanguageMap: Record<string, string> = {
  'AU': 'English',
  'DE': 'German',
  'FR': 'French',
  'ES': 'Spanish',
  'IT': 'Italian',
  'US': 'English',
  'GB': 'English',
  'CA': 'English', // Englisch und Französisch, hier Englisch als Standard
  'CN': 'Chinese',
  'JP': 'Japanese',
  'KR': 'Korean',
  'BR': 'Portuguese',
  'IN': 'Hindi', // Englisch ist ebenfalls weit verbreitet
  'RU': 'Russian',
  'MX': 'Spanish',
  'NL': 'Dutch',
  'SE': 'Swedish',
  'NO': 'Norwegian',
  'FI': 'Finnish',
  'DK': 'Danish',
  'PL': 'Polish',
  'PT': 'Portuguese',
  'AR': 'Spanish',
  'CH': 'German', // Deutsch, Französisch und Italienisch; hier Deutsch als Standard
  'BE': 'Dutch', // Niederländisch, Französisch und Deutsch; hier Niederländisch als Standard
  'AT': 'German',
  'NZ': 'English',
  'ZA': 'English', // Südafrika hat mehrere offizielle Sprachen; Englisch als Standard
  'IE': 'English',
  'SG': 'English', // Englisch ist eine der Amtssprachen in Singapur
  'MY': 'Malay',
  'TH': 'Thai',
  'VN': 'Vietnamese',
  'PH': 'Filipino', // Englisch ist ebenfalls weit verbreitet
  'TR': 'Turkish',
  'EG': 'Arabic',
  'SA': 'Arabic',
  'AE': 'Arabic',
  'IL': 'Hebrew',
  'GR': 'Greek',
  'CZ': 'Czech',
  'HU': 'Hungarian',
  'RO': 'Romanian',
  'SK': 'Slovak',
  'BG': 'Bulgarian',
  'UA': 'Ukrainian',
};

const isoToFullCountryMap: Record<string, string> = {
  'AU': 'Australia',
  'DE': 'Germany',
  'FR': 'France',
  'ES': 'Spain',
  'IT': 'Italy',
  'US': 'United States',
  'GB': 'United Kingdom',
  'CA': 'Canada',
  'CN': 'China',
  'JP': 'Japan',
  'KR': 'South Korea',
  'BR': 'Brazil',
  'IN': 'India',
  'RU': 'Russia',
  'MX': 'Mexico',
  'NL': 'Netherlands',
  'SE': 'Sweden',
  'NO': 'Norway',
  'FI': 'Finland',
  'DK': 'Denmark',
  'PL': 'Poland',
  'PT': 'Portugal',
  'AR': 'Argentina',
  'CH': 'Switzerland',
  'BE': 'Belgium',
  'AT': 'Austria',
  'NZ': 'New Zealand',
  'ZA': 'South Africa',
  'IE': 'Ireland',
  'SG': 'Singapore',
  'MY': 'Malaysia',
  'TH': 'Thailand',
  'VN': 'Vietnam',
  'PH': 'Philippines',
  'TR': 'Turkey',
  'EG': 'Egypt',
  'SA': 'Saudi Arabia',
  'AE': 'United Arab Emirates',
  'IL': 'Israel',
  'GR': 'Greece',
  'CZ': 'Czech Republic',
  'HU': 'Hungary',
  'RO': 'Romania',
  'SK': 'Slovakia',
  'BG': 'Bulgaria',
  'UA': 'Ukraine',
};


export const regionToLanguage = (iso: string): string => {
  return isoToFullLanguageMap[iso] || 'English' // Standard: 'English', wenn kein Mapping vorhanden
}

export const regionToCountry = (iso: string): string => {
  return isoToFullCountryMap[iso] || 'United States' // Standard: 'United States', wenn kein Mapping vorhanden
}


export const isoToLanguage = (iso: string): string =>
  iso.split(ISO_SEPARATOR)[0]

export const isoToRegion = (iso: string): string => {
  const region = iso.split(ISO_SEPARATOR)[1]
  return region === 'LIE' ? 'CH' : region
}

export const isoToApolloClient = (iso: string): string =>
  `shopify${iso.replace('-', '')}`

export const isoToCurrency = (iso: string): string => {
  const region = isoToRegion(iso) as Region['id']
  return config.regions.find((r) => r.id === region)!.currency
}

// ['en-CH', 'de-CH', 'de-DE' ...]
export const isos = regions
  .map((region) =>
    regionsToLanguages[region].map((language) => makeIso(region, language))
  )
  .reduce((acc, arr) => acc.concat(arr), [])

export const userLanguageFromRegion = (region: string): string => {
  const userLanguage = process.client
    ? isoToLanguage(window.navigator.language)
    : ''
  return regionsToLanguages[region].includes(userLanguage)
    ? userLanguage
    : regionsToDefaultLanguages[region]
}

export const userSlugFromRegion = (region: string): string => {
  const iso = makeIso(region, userLanguageFromRegion(region))
  return isoToSlug(iso)
}

export const formatPrice = (
  price: number,
  region: string,
  language: string,
  currency: string
): string => {
  // Formatiere den Preis standardmäßig
  let formattedPrice = Number(price).toLocaleString(
    `${language}-${region.replace('EU', 'DE')}`,
    {
      style: 'currency',
      currencyDisplay: 'symbol',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  );

  // Spezielle Behandlung für AUD, CAD, NZD und NOK
  if (currency === 'AUD') {
    formattedPrice = formattedPrice.replace('A$', '$');
    formattedPrice = `AUD ${formattedPrice}`
  } else if (currency === 'CAD') {
    formattedPrice = formattedPrice.replace('CA$', '$');
    formattedPrice = `CAD ${formattedPrice}`
  } else if (currency === 'NZD') {
    formattedPrice = formattedPrice.replace('NZ$', '$');
    formattedPrice = `NZD ${formattedPrice}`
  } else if (currency === 'NOK') {
    // NOK anpassen, um das Symbol "kr" am Ende anzuzeigen
    formattedPrice = formattedPrice.replace('NOK', '').trim(); // NOK entfernen
    formattedPrice += ' kr'; // "kr" am Ende hinzufügen
  }

  return formattedPrice;
};

export const formatPriceShippingCost = (
  price: number,
  region: string,
  language: string,
  currency: string
): string => {
  // Wenn die Region EU ist, setzen wir die Währung auf Euro (EUR)
  if (region === 'EU') {
    currency = 'EUR';
  }

  // Wenn die Region US ist, setzen wir die Währung auf US-Dollar (USD)
  if (region === 'US') {
    currency = 'USD';
  }

  const formattedPrice = Number(price).toLocaleString(
    `${language}-${region.replace('EU', 'DE')}`,
    {
      style: 'currency',
      currencyDisplay: 'symbol',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  );

  return formattedPrice;
}
