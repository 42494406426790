/* eslint-disable */
const { compileDesignTokens } = require('./abstract-automation/tailwind')
const BsgGridPlugin = require('./tailwind-plugins/bsg-grid')
const BsgContainerPlugin = require('./tailwind-plugins/bsg-container')
const ColorsCss = require('./tailwind-plugins/colors-css')
/* eslint-enable */

const designTokens = compileDesignTokens()

module.exports = {
  corePlugins: {
    backgroundImage: false,
    gradientColorStops: false,
    container: false,
    gridAutoColumns: false,
    gridAutoFlow: false,
    gridAutoRows: false,
    gridColumn: false,
    gridColumnEnd: false,
    gridColumnStart: false,
    gridRow: false,
    gridRowEnd: false,
    gridRowStart: false,
    gridTemplateColumns: false,
    gridTemplateRows: false,
  },
  theme: {
    ...designTokens.theme,
    fontFamily: {
      'pangea-bold': ['PangeaText-Bold', 'sans-serif'],
      'pangea-light': ['PangeaText-Light', 'sans-serif'],
      'pangea-medium': ['PangeaText-Medium', 'sans-serif'],
      'pangea-regular': ['PangeaText-Regular', 'sans-serif'],
      'pangea-semi-bold': ['PangeaText-SemiBold', 'sans-serif'],
    },
    screens: {
      xs: '375px',
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
      '-xs': { max: '374px' },
      '-sm': { max: '639px' },
      '-md': { max: '767px' },
      '-lg': { max: '1023px' },
      '-xl': { max: '1279px' },
      '-2xl': { max: '1535px' },
    },
    transitionDuration: {
      0: '0ms',
      100: '150ms',
      200: '200ms',
      300: '300ms',
      400: '400ms',
      500: '500ms',
    },
    transitionTimingFunction: {
      out: 'cubic-bezier(0, 0, 0.40, 1)',
      in: 'cubic-bezier(0.50, 0, 1, 1)',
      'in-out': 'cubic-bezier(0.45, 0, 0.40, 1)',
    },
  },
  variants: {
    utilities: ['responsive'],
    extend: {
      backgroundColor: ['active'],
    },
  },
  plugins: [
    ...designTokens.plugins,
    BsgGridPlugin,
    BsgContainerPlugin,
    ColorsCss,
  ],
  content: [
    'components/**/*.vue',
    'layouts/**/*.vue',
    'pages/**/*.vue',
    'plugins/**/*.{js,ts}',
    'nuxt.config.ts',
    // FUTURE: separate styleguide
    'styleguide/docs/**.*',
    'styleguide/components/**/*.vue',
    'styleguide/patterns/**/*.vue',
    'styleguide/**/*.stories.js',
  ],
}
