















































import Vue from 'vue'
import { PortalTarget } from 'portal-vue'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import functionalUrls from './functional-urls'
import { layoutHead as head } from '~/services/seo'
import { Look as FooterLook } from '~/styleguide/patterns/Footer.vue'
import { isValidLocale, slugToIso } from '~/services/i18n'
import {
  zendeskOpen,
  zendeskClose,
  zendeskSnippet,
  zendeskSetLocale,
} from '~/services/zendesk'
import appConfig from '~/app.config'
import { getGeolocationRegion } from '~/services/user-locale'
import {
  trustedShopsClose,
  trustedShopsInit,
  trustedShopsVisibility,
} from '~/services/trusted-shops'
import vuex from "~/plugins/vuex";

export const selectNavbarTheme = (theme: 'light' | 'dark'): void => {
  Vue.nextTick(() =>
    window.dispatchEvent(
      new CustomEvent('selectNavbarTheme', { detail: { theme } })
    )
  )
}

export const selectNavbarPosition = (position: 'fixed' | 'absolute'): void => {
  Vue.nextTick(() =>
    window.dispatchEvent(
      new CustomEvent('selectNavbarPosition', { detail: { position } })
    )
  )
}

export const selectFooterLook = (look: FooterLook): void => {
  Vue.nextTick(() =>
    window.dispatchEvent(
      new CustomEvent('selectFooterLook', { detail: { look } })
    )
  )
}

export default Vue.extend({
  name: 'LayoutsDefault',
  components: {
    PortalTarget,
  },
  async middleware(ctx) {
  const { $vuex, route, redirect } = ctx;

  // Überprüfen auf fehlendes abschließendes `/` und Umleitung
  if (route.path !== '/' && !route.path.endsWith('/')) {
  const pathWithSlash = `${route.path}/`;
  const fullPath = { path: pathWithSlash, query: route.query };
  return redirect(301, fullPath); // Sofortige Umleitung mit Query-Parameter
}

// Prüfe, ob die Route in `functionalUrls` existiert und führe die zugehörige Funktion aus
if (Object.keys(functionalUrls).includes(route.path)) {
  await functionalUrls[route.path](ctx);
} else {
  // Lokalisierung basierend auf dem ersten URL-Segment
  const routeSegments = route.path.split('/').filter(segment => segment);
  const routeLocale = routeSegments.length > 0 ? slugToIso(routeSegments[0]) : null;
  const validLocale = routeLocale && isValidLocale(routeLocale) ? routeLocale : appConfig.defaultLocale;

  // Setze die erkannte oder Standard-Locale im Store
  $vuex.commit('INIT_LOCALE_SSR', validLocale);
}
},
  data() {
    return {
      isMounted: false,
      navbarTheme: 'light' as 'light' | 'dark',
      navbarPosition: 'fixed' as 'fixed' | 'absolute',
      footerLook: 'dark-neutral' as FooterLook,
      isRegionSwitcherOpen: false,
      geolocationRegion: '',
    }
  },
  head,
  computed: {
    isConfigurator(): boolean {
      return (
        !!this.$route.name?.startsWith('configurator') &&
        !this.$route.name?.startsWith('configurator-equipment')
      )
    },
    isAuth(): boolean {
      return !!this.$route.name?.startsWith('auth')
    },
    isOrderConfirmation(): boolean {
      return !!this.$route.name?.startsWith('order-confirmation')
    },
    isVideoPlattform(): boolean {
      return !!this.$route.name?.startsWith('video')
    },
    isVideoFind(): boolean {
      return !!this.$route.name?.startsWith('video-find-')
    },
    showSupportButton(): boolean {
      return (
        this.isMounted &&
        !this.isConfigurator &&
        !this.isVideoPlattform &&
        this.$zendesk.isAvailable &&
        appConfig.zendeskLocales.includes(slugToIso(this.$i18n.locale))
      )
    },
    showNavbar(): boolean {
      return (
        !this.isConfigurator &&
        !this.isAuth &&
        !this.isOrderConfirmation &&
        !this.isVideoPlattform
      )
    },
    showFooter(): boolean {
      return (
        !this.isConfigurator &&
        !this.isAuth &&
        !this.isOrderConfirmation &&
        !this.isVideoPlattform
      )
    },
  },
  watch: {
    showSupportButton(newVal) {
      if (newVal === false && this.$zendesk.isAvailable) zendeskClose()
    },
    '$i18n.locale': function () {
      trustedShopsVisibility(this.$vuex.getters.language, this.$route.name)
    },
    '$vuex.getters.region': {
      handler(newRegion, oldRegion) {
        if (newRegion !== oldRegion) {
          // @ts-ignore: Ignore the TS error since $loadTracify is injected at runtime
          this.$loadTracifyScripts(newRegion)
        }
      },
      immediate: true,
    },
    '$vuex.getters.language': {
      handler(newLanguage, oldLanguage) {
        if (process.client) {
          if (newLanguage !== oldLanguage) {
            zendeskSetLocale(newLanguage)
          }
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    if (!this.$route.name?.startsWith('cart')) {
      this.$vuex.dispatch('cart/get')
    }
    this.$vuex.dispatch('auth/load')

    window.addEventListener('CookiebotOnDialogDisplay', this.onCookiebotOpen)
    window.addEventListener('CookiebotOnAccept', this.onCookiebotClose)
    window.addEventListener('CookiebotOnDecline', this.onCookiebotClose)

    window.addEventListener('selectNavbarTheme', this.selectNavbarTheme)
    window.addEventListener('selectNavbarPosition', this.selectNavbarPosition)
    window.addEventListener('selectFooterLook', this.selectFooterLook)

    const userRegion = await getGeolocationRegion(this)
    if (
      this.$route.path !== '/' &&
      !this.isVideoPlattform &&
      userRegion &&
      userRegion !== this.$vuex.getters.region
    ) {
      this.geolocationRegion = userRegion
      this.isRegionSwitcherOpen = true
    }
    window.dispatchEvent(new CustomEvent('mountLayout'))
    if (window.isCookiebotOpen) this.onCookiebotOpen()
    zendeskSnippet(this.$vuex.getters.region)

    this.trustedShopsInit(this.$vuex.getters.region)

    // Trusted shops initalized
    window.addEventListener('trustedShopsLoaded', () => {
      trustedShopsVisibility(this.$vuex.getters.language, this.$route.name)

      this.$watch('$route', () => {
        trustedShopsVisibility(this.$vuex.getters.language, this.$route.name)
      })
    })

    if (this.isVideoPlattform || this.isVideoFind) {
      trustedShopsClose()
    }
    this.isMounted = true
  },
  beforeDestroy() {
    window.removeEventListener('CookiebotOnDialogDisplay', this.onCookiebotOpen)
    window.removeEventListener('CookiebotOnAccept', this.onCookiebotClose)
    window.removeEventListener('CookiebotOnDecline', this.onCookiebotClose)
    window.removeEventListener('selectNavbarTheme', this.selectNavbarTheme)
    window.removeEventListener(
      'selectNavbarPosition',
      this.selectNavbarPosition
    )
    window.removeEventListener('selectFooterLook', this.selectFooterLook)
  },
  methods: {
    trustedShopsInit,
    onCookiebotOpen() {
      const banner = document.getElementById('CookieBanner')
      if (banner)
        disableBodyScroll(banner, {
          allowTouchMove: () => true,
        })
    },
    onCookiebotClose() {
      const banner = document.getElementById('CookieBanner')
      if (banner) enableBodyScroll(banner)
    },
    selectNavbarTheme(e: Event) {
      const event = e as CustomEvent
      this.navbarTheme = event.detail.theme
    },
    selectNavbarPosition(e: Event) {
      const event = e as CustomEvent
      this.navbarPosition = event.detail.position
    },
    selectFooterLook(e: Event) {
      const event = e as CustomEvent
      this.footerLook = event.detail.look
    },
    onSupport() {
      zendeskOpen()
    },
    experifyClick() {
      if (Experify) {
        Experify.openExperifyPlugin()
      }
    },
  },
})
