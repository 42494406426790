/* eslint-disable */
const plugin = require('tailwindcss/plugin')
const designTokens = require('../tailwind.tokens.json')
/* eslint-enable */

// helper functions
const isCssClass = (obj) => typeof Object.entries(obj)[0][1] !== 'object'
const applyPrefix = (prefix, name) =>
  `${prefix}${prefix !== '.' ? '-' : ''}${name}`
const unwrap = (prefix, entries) =>
  entries.reduce(
    (acc, entry) =>
      isCssClass(entry[1])
        ? acc.concat([[applyPrefix(prefix, entry[0]), entry[1]]])
        : acc.concat(
            unwrap(applyPrefix(prefix, entry[0]), Object.entries(entry[1]))
          ),
    []
  )
const generateClasses = (pluginConfigData) =>
  Object.fromEntries(unwrap('.', Object.entries(pluginConfigData)))

// plugins
const typographyPlugin = (configData) =>
  plugin(
    function ({ addUtilities }) {
      const typoClasses = generateClasses(configData)
      addUtilities(typoClasses, {
        respectPrefix: false,
        respectImportant: false,
        variants: ['responsive'],
      })
    },
    {
      theme: {
        // provide classes for storybook styleguide
        typography: () => configData,
      },
    }
  )

const gradientsPlugin = (configData) =>
  plugin(
    function ({ addUtilities }) {
      const gradientClasses = generateClasses(configData)
      addUtilities(gradientClasses, {
        respectPrefix: false,
        respectImportant: false,
        variants: [],
      })
    },
    {
      theme: {
        // provide classes for storybook styleguide
        gradients: () => configData,
      },
    }
  )

const elevationsPlugin = (configData) =>
  plugin(
    function ({ addUtilities }) {
      const elevationClasses = generateClasses(configData)
      addUtilities(elevationClasses, {
        respectPrefix: false,
        respectImportant: false,
        variants: [],
      })
    },
    {
      theme: {
        // provide classes for storybook styleguide
        elevations: () => configData,
      },
    }
  )

module.exports.compileDesignTokens = () => ({
  theme: designTokens.theme,
  plugins: [
    typographyPlugin(designTokens.plugins.typography),
    gradientsPlugin(designTokens.plugins.gradients),
    elevationsPlugin(designTokens.plugins.elevations),
  ],
})
