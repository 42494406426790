import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '~/store'

import { isoToApolloClient, regions, slugToIso } from '~/services/i18n'
import { trackEvent } from '~/services/tracking'

import { ResponseType as Cart } from '~/graphql/cart/cart'
import { query, unwrapResponse, VariableType } from '~/graphql/cart/get'
import {
  query as createQuery,
  unwrapResponse as createUnwrapResponse,
  VariableType as createVariableType,
} from '~/graphql/cart/create'
import {
  query as linesAddQuery,
  unwrapResponse as linesAddUnwrapResponse,
  VariableType as linesAddVariableType,
} from '~/graphql/cart/linesAdd'
import {
  query as linesUpdateQuery,
  unwrapResponse as linesUpdateUnwrapResponse,
  VariableType as linesUpdateVariableType,
} from '~/graphql/cart/linesUpdate'
import {
  query as linesRemoveQuery,
  unwrapResponse as linesRemoveUnwrapResponse,
  VariableType as linesRemoveVariableType,
} from '~/graphql/cart/linesRemove'
import { Shopify } from '~/graphql/types'
import appConfig from "~/app.config";

export type CartState = {
  shop: {
    [region: string]: Cart['id'] | undefined
  }
  checkoutUrl: Cart['checkoutUrl'] | undefined
  attributes: Cart['attributes']
  lines: Cart['lines']
  estimatedCost: Cart['estimatedCost'] | undefined
  errors: string[]
  loading: boolean
}

const isExpired = (userErrors: Shopify.Error[]) =>
  userErrors.reduce((acc: boolean, error: Shopify.Error) => {
    if (error.code === 'INVALID') acc = true
    return acc
  }, false)

const trackCart = (
  locale: string,
  name: string,
  cart: Cart,
  merchandiseId?: string,
  removeIds?: string[]
): void => {
  try {
    const product = merchandiseId
      ? cart.lines.find((line) => line.merchandise.id === merchandiseId)!
      : undefined

    // add and update
    if (product) {
      const data = {
        id: product.merchandise.id,
        title: `${product.merchandise.product.title}`,
        variant: `${product.merchandise.title}`,
        attributes: product.attributes.map((a) => `${a.key}:${a.value}`),
        value: product.estimatedCost.totalAmount.amount,
        quantity: product.quantity,
      }

      trackEvent(locale, name, {
        type: 'product',
        ...data,
      })
    }

    // remove
    if (removeIds) {
      trackEvent(locale, name, {
        type: 'product',
        ids: removeIds.join(','),
      })
    }
  } catch {
    // ignore tracking errors
  }
}

const cleanShopIds = regions.reduce((acc, region) => {
  acc[region] = undefined
  return acc
}, {} as Record<string, undefined>)

export const state = () =>
  ({
    shop: cleanShopIds,
    checkoutUrl: undefined,
    attributes: [],
    lines: [],
    estimatedCost: undefined,
    errors: [],
    loading: true,
  } as CartState)

export const getters: GetterTree<CartState, RootState> = {
  count(state): number {
    const nonAssemblyLines = state.lines.filter(line => line.merchandise.sku !== appConfig.assemblySKU);

    return nonAssemblyLines.length > 0
      ? nonAssemblyLines.reduce((acc: number, line) => (acc += line.quantity), 0)
      : 0;
  },
  maxQuantity(): number {
    return 99
  },
  quantityInCart(state) {
    return (id: string) =>
      state.lines.reduce((acc, line) => {
        const quantity = line.merchandise.id === id ? line.quantity : 0
        return acc + quantity
      }, 0)
  },
}

export const mutations: MutationTree<CartState> = {
  updateCartId(state, cartIds) {
    state.shop = cartIds
  },
  loading(state, loading) {
    state.loading = loading
  },
  update(state, payload) {
    state.shop[payload.region as string] = payload.cart.id
    state.checkoutUrl = payload.cart.checkoutUrl
    state.attributes = payload.cart.attributes
    state.lines = payload.cart.lines
    state.estimatedCost = payload.cart.estimatedCost
  },
  error(state, errors) {
    state.errors = errors
  },
  clear(state) {
    state.shop = cleanShopIds
    state.checkoutUrl = undefined
    state.attributes = []
    state.lines = []
    state.estimatedCost = undefined
    state.errors = []
  },
  clearLines(state) {
    state.checkoutUrl = undefined
    state.attributes = []
    state.lines = []
    state.estimatedCost = undefined
    state.errors = []
  },
}

export const actions: ActionTree<CartState, RootState> = {
  loading({ commit }, isLoading) {
    commit('loading', isLoading)
  },
  update({ commit, rootGetters }, { cart, doNotUpdateCookie }) {
    commit('update', {
      cart,
      region: rootGetters.region,
      language: rootGetters.language,
      doNotUpdateCookie,
    })
  },
  error({ commit }, errors) {
    commit('error', errors)
    setTimeout(() => commit('error', []), 5000)
  },
  expired(
    { commit, dispatch },
    {
      addLinesAgain,
    }: {
      addLinesAgain?: linesAddVariableType['lines']
    }
  ) {
    commit('clear')
    if (addLinesAgain) dispatch('addLine', addLinesAgain)
  },
  async get(
    { state, rootGetters, commit, dispatch, rootState },
    options: { noVisualLoading: boolean }
  ) {
    if (!options || !options.noVisualLoading) dispatch('loading', true)
    const cartId = state.shop[rootGetters.region]
    // only fetch cart if already created
    if (!cartId) {
      commit('clearLines')
      dispatch('loading', false)
      return
    }
    const errors: string[] = []
    try {
      const baseRegion = rootState.i18n.baseRegion;
      const country = (
          (['AU', 'CA', 'NZ',].includes(baseRegion) &&
            rootState.i18n.locale === 'en-US') || baseRegion === 'GB'  || baseRegion === 'NO'
      ) ? baseRegion : undefined;

      const response = await this.app.apolloProvider?.clients[
        isoToApolloClient(slugToIso(rootState.i18n.locale))
      ].query({
        fetchPolicy: 'no-cache',
        query,
        variables: {
          cartId,
          lng: rootGetters.language.toUpperCase(),
          country,
        } as VariableType,
      })
      if (response?.data) {
        const cart = unwrapResponse(response.data)
        if (cart) dispatch('update', { cart, doNotUpdateCookie: true })
      }
    } catch (e) {
      errors.push(this.app.i18n.t('ERROR_GRAPHQL') as string)
    }
    if (errors.length > 0) dispatch('error', errors)
    dispatch('loading', false)
  },
  async addLine(
    { state, rootGetters, dispatch, rootState },
    payload:
      | linesAddVariableType['lines']
      | { lines: linesAddVariableType['lines']; displayLoading: boolean }
  ) {
    const lines = Array.isArray(payload) ? payload : payload.lines
    const displayLoading = !Array.isArray(payload)
      ? payload.displayLoading
      : true
    const cartId = state.shop[rootGetters.region]
    // create cart with first item
    const createCart = !cartId

    let response
    let errors: string[] = []

    dispatch('loading', displayLoading)
    try {
      const baseRegion = rootState.i18n.baseRegion;
      const country = (
        (['AU', 'CA', 'NZ',].includes(baseRegion) &&
          rootState.i18n.locale === 'en-US') || baseRegion === 'GB' || baseRegion === 'NO'
      ) ? baseRegion : undefined

      if (createCart) {
        response = await this.app.apolloProvider?.clients[
          isoToApolloClient(slugToIso(rootState.i18n.locale))
          ].mutate({
          fetchPolicy: 'no-cache',
          mutation: createQuery,
          variables: {
            cartInput: { lines },
            lng: rootGetters.language.toUpperCase(),
            country, // baseRegion wird als country hinzugefügt, wenn AU oder CA
          } as createVariableType,
        })
      } else {
        response = await this.app.apolloProvider?.clients[
          isoToApolloClient(slugToIso(rootState.i18n.locale))
          ].mutate({
          fetchPolicy: 'no-cache',
          mutation: linesAddQuery,
          variables: {
            cartId,
            lines,
            lng: rootGetters.language.toUpperCase(),
            country, // baseRegion wird als country hinzugefügt, wenn AU oder CA
          } as linesAddVariableType,
        })
      }

      if (response?.data) {
        const data = createCart
          ? createUnwrapResponse(response.data.cartCreate)
          : linesAddUnwrapResponse(response.data.cartLinesAdd)
        if (data?.cart) {
          dispatch('update', { cart: data.cart })
          trackCart(
            this.$i18n.locale,
            'AddToCart',
            data.cart,
            lines[0].merchandiseId
          )
        }
        if (data?.userErrors) {
          if (isExpired(data.userErrors))
            dispatch('expired', {
              addLinesAgain: lines,
            })
          errors = data.userErrors.map((error) => error.message)
        }
      }
    } catch (e) {
      errors.push(this.app.i18n.t('ERROR_GRAPHQL') as string)
    }
    if (displayLoading) dispatch('loading', false)
    if (errors.length > 0) dispatch('error', errors)
  },
  async updateLine(
    { state, rootGetters, dispatch, rootState },
    lines: linesUpdateVariableType['lines']
  ) {
    let response
    let errors: string[] = []

    const cartId = state.shop[rootGetters.region]

    try {
      response = await this.app.apolloProvider?.clients[
        isoToApolloClient(slugToIso(rootState.i18n.locale))
      ].mutate({
        fetchPolicy: 'no-cache',
        mutation: linesUpdateQuery,
        variables: {
          cartId,
          lines,
          lng: rootGetters.language.toUpperCase(),
        } as linesUpdateVariableType,
      })

      if (response?.data) {
        const data = linesUpdateUnwrapResponse(response.data.cartLinesUpdate)
        if (data?.cart) {
          dispatch('update', { cart: data.cart })
          trackCart(
            this.$i18n.locale,
            'UpdateCart',
            data.cart,
            lines[0].merchandiseId
          )
        }
        if (data?.userErrors) {
          if (isExpired(data.userErrors)) {
            dispatch('expired')
          }
          errors = data.userErrors.map((error) => error.message)
        }
      }
    } catch (e) {
      errors.push(this.app.i18n.t('ERROR_GRAPHQL') as string)
    }
    dispatch('error', errors)
  },
  async removeLine(
    { state, rootGetters, dispatch, rootState },
    lineIds: string[]
  ) {
    let errors: string[] = []

    const cartId = state.shop[rootGetters.region]

    try {
      const response = await this.app.apolloProvider?.clients[
        isoToApolloClient(slugToIso(rootState.i18n.locale))
      ].mutate({
        fetchPolicy: 'no-cache',
        mutation: linesRemoveQuery,
        variables: {
          cartId,
          lineIds,
          lng: rootGetters.language.toUpperCase(),
        } as linesRemoveVariableType,
      })

      if (response?.data) {
        const data = linesRemoveUnwrapResponse(response.data.cartLinesRemove)
        if (data?.cart) {
          dispatch('update', { cart: data.cart })
          trackCart(
            this.$i18n.locale,
            'RemoveFromCart',
            data.cart,
            undefined,
            lineIds
          )
        }
        if (data?.userErrors) {
          if (isExpired(data.userErrors)) {
            dispatch('expired')
          }
          errors = data.userErrors.map((error) => error.message)
        }
      }
    } catch (e) {
      errors.push(this.app.i18n.t('ERROR_GRAPHQL') as string)
    }
    dispatch('error', errors)
  },
}
