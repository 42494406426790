











import Vue, { PropType } from 'vue'
import { enumValidator } from '~/services/enum-props'
import { getColorNames, getColorStyle } from '~/styleguide/utils'

export default Vue.extend({
  name: 'BSGParagraph',
  props: {
    centered: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    color: {
      type: String as PropType<string>,
      default: 'neutral-800',
      validator: enumValidator(getColorNames()),
    },
  },
  computed: {
    /* Use color value to prevent purging tailwind class */
    colorStyle(): Partial<CSSStyleDeclaration> {
      return getColorStyle(this.color)
    },
  },
  mounted() {
    // eslint-disable-next-line no-console
    if (!this.$slots.default) console.error('Default slot is required')
  },
})
