import Vue, { VueConstructor } from 'vue'

export const enumValidator = (options: string[]): ((s: string) => boolean) => {
  const f = (s: string) => options.includes(s)
  f.enumOptions = options
  return f
}

export const enumOptions = (
  component: VueConstructor<Vue>,
  propName: string
): string[] =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (component as any).options.props[propName].validator.enumOptions as string[]
