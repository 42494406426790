

















import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'BSGButtonGroup',
  props: {
    centered: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    reverse: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    // full width buttons (only on mobile)
    fullWidth: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    // flips button direction (only for tablet)
    flip: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
})
