import { randomBytes, createHash } from 'crypto'

const base64URLEncode = (str: Buffer) =>
  str
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '')

const sha256 = (buffer: string) => createHash('sha256').update(buffer).digest()

export const generatePkcePair = (): {
  codeChallenge: string
  codeVerifier: string
} => {
  const codeVerifier = base64URLEncode(randomBytes(32))
  const codeChallenge = base64URLEncode(sha256(codeVerifier))
  return { codeVerifier, codeChallenge }
}
