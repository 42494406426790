// eslint-disable-next-line
const plugin = require('tailwindcss/plugin')
module.exports = plugin(function ({ addBase, addUtilities }) {
  const maxColumns = 12
  const columnGap = '24px'
  const rowGap = '0px'
  const gridClasses = {}
  const gridCss = (i) => ({
    display: 'grid',
    'grid-auto-flow': 'row',
    'grid-template-rows': 'auto',
    'row-gap': 'var(--grid-row-gap, 10px)',
    'column-gap': 'var(--grid-column-gap, 10px)',
    '& > *':
      typeof i === 'number'
        ? {
            '--grid-width': `${i}`,
            '--grid-template-columns': `repeat(${i}, 1fr)`,
          }
        : undefined,
    'grid-template-columns':
      typeof i === 'number'
        ? `repeat(${i}, 1fr)`
        : 'var(--grid-template-columns)',
  })
  const cellCss = (i) => ({
    '--grid-cell-width': `${i}`,
    'grid-column-end': 'span var(--grid-cell-width, --grid-width)',
    'justify-self': 'stretch',
    'align-self': 'stretch',
  })
  const cellOffsetLeftCss = (i) => ({
    'grid-column-start': `${i + 1}`,
  })
  const cellOffsetRightCss = (i) => ({
    'grid-column-start': 'span var(--grid-cell-width, --grid-width)',
    'grid-column-end': `calc(var(--grid-width) - ${i - 1})`,
  })
  const subgridCss = (i) => ({
    ...cellCss(i),
    ...gridCss(i),
  })
  for (let i = 1; i <= maxColumns; ++i) {
    gridClasses[`.grid-${i}`] = gridCss(i)
  }
  for (let i = 1; i <= maxColumns; ++i) {
    gridClasses[`.grid-cell-${i}`] = cellCss(i)
  }
  for (let i = 1; i <= maxColumns; ++i) {
    gridClasses[`.grid-cell-left-${i}`] = cellOffsetLeftCss(i)
  }
  for (let i = 1; i <= maxColumns; ++i) {
    gridClasses[`.grid-cell-right-${i}`] = cellOffsetRightCss(i)
  }
  for (let i = 1; i <= maxColumns; ++i) {
    gridClasses[`.grid-subgrid-${i}`] = subgridCss(i)
  }
  gridClasses[`.grid-cell-left-none`] = gridClasses[`.grid-cell-right-none`] = {
    'grid-column-start': 'unset',
  }
  gridClasses[`.grid-cell-left-0`] = cellOffsetLeftCss(0)
  gridClasses[`.grid-cell-right-0`] = cellOffsetRightCss(0)
  gridClasses[`.grid-cell-full`] = cellCss('var(--grid-width)')
  gridClasses[`.grid-subgrid-full`] = subgridCss('var(--grid-width)')
  addUtilities(gridClasses, { variants: ['responsive'] })
  addBase({
    body: {
      '--grid-row-gap': rowGap,
      '--grid-column-gap': columnGap,
    },
  })
})
