










import Vue, { PropType } from 'vue'
import BSGButton from '../components/Button.vue'

export default Vue.extend({
  name: 'PatternSubBar',
  components: {
    BSGButton,
  },
  props: {
    name: {
      type: String as PropType<string>,
      required: true,
    },
    buttonText: {
      type: String as PropType<string>,
      required: true,
    },
  },
})
