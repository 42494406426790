



























import Vue from 'vue'

export default Vue.extend({
  name: 'LayoutsError',
  layout: 'error',
  props: { error: { type: Object, required: true } },
})
