import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1dc9f344&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=1dc9f344&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dc9f344",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BSGCardSelectable: require('/workspace/styleguide/components/card/Selectable.vue').default,BSGButton: require('/workspace/styleguide/components/Button.vue').default,BSGButtonGroup: require('/workspace/styleguide/components/ButtonGroup.vue').default,BSGModal: require('/workspace/styleguide/components/Modal.vue').default})
