
























import Vue, { PropType } from 'vue'
import BSGButton from '../components/Button.vue'
import { Links } from './NavBar.vue'

export default Vue.extend({
  name: 'PatternNavBarFooter',
  components: {
    BSGButton,
  },
  props: {
    followText: { type: String as PropType<string>, required: true },
    socialLinks: {
      type: Array as PropType<Required<Links>[]>,
      required: true,
    },
  },
})
