import { render, staticRenderFns } from "./NavBar.vue?vue&type=template&id=ca20b794&scoped=true&"
import script from "./NavBar.vue?vue&type=script&lang=ts&"
export * from "./NavBar.vue?vue&type=script&lang=ts&"
import style0 from "./NavBar.vue?vue&type=style&index=0&id=ca20b794&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca20b794",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BSGLogo: require('/workspace/styleguide/components/Logo.vue').default,BSGLink: require('/workspace/styleguide/components/Link.vue').default,PatternNavBarLinks: require('/workspace/styleguide/patterns/NavBarLinks.vue').default,BSGIcon: require('/workspace/styleguide/components/Icon.vue').default,BSGButton: require('/workspace/styleguide/components/Button.vue').default,PatternNavBarFooter: require('/workspace/styleguide/patterns/NavBarFooter.vue').default})
