import Vuex, { GetterTree, ActionTree, MutationTree } from 'vuex'
import { AuthState } from './auth'
import { UserState } from './user'
import { CartState } from './cart'
import { ConfiguratorState } from './configurator'
import {
  isoToLanguage,
  isoToRegion,
  isoToCurrency,
  slugToIso,
  isoToSlug,
} from '~/services/i18n'

import appConfig from '~/app.config'

export type RootState = {
  auth: AuthState
  user: UserState
  i18n: {
    locale: string
    baseRegion: string // baseIso zum i18n-Objekt hinzugefügt
    showChangeLocale: boolean
    isInitialized: boolean,
  }
  cart: CartState
  configurator: ConfiguratorState
}

export const state = () =>
  ({
    i18n: {
      locale: appConfig.defaultLocale, // Set by INIT_LOCALE_SSR in nuxt config
      baseRegion: '', // Initialwert für den baseIso innerhalb von i18n
      showChangeLocale: false,
      isInitialized: false,
    },
  } as RootState)

export const getters: GetterTree<RootState, RootState> = {
  region: (_state , _getters, rootState) => {
    const locale = slugToIso(rootState.i18n.locale)
    return locale && locale.includes('-')
      ? isoToRegion(locale)
      : isoToRegion(appConfig.defaultLocale)
  },
  language: (_state, _getters, rootState) => {
    const locale = slugToIso(rootState.i18n.locale)
    return locale && locale.includes('-')
      ? isoToLanguage(locale)
      : isoToLanguage(appConfig.defaultLocale)
  },
  currency: (_state, _getters, rootState) => {
    const baseRegion = rootState.i18n.baseRegion // Annahme: baseRegion ist bereits umbenannt und vorhanden
    const locale = slugToIso(rootState.i18n.locale)

    if (rootState.i18n.locale === 'en-US') {
      if (baseRegion === 'AU') {
        return 'AUD'; // Australischer Dollar
      } else if (baseRegion === 'CA') {
        return 'CAD'; // Kanadischer Dollar
      } else if (baseRegion === 'NZ') {
        return 'NZD'; // Neuseeland-Dollar
      }
    }

    if (rootState.i18n.locale === 'en-US') {
      if (baseRegion === 'AU') {
        return 'AUD'; // Australischer Dollar
      } else if (baseRegion === 'CA') {
        return 'CAD'; // Kanadischer Dollar
      } else if (baseRegion === 'NZ') {
        return 'NZD'; // Neuseeland-Dollar
      }
    }

    if ( baseRegion === 'GB' ) {
        return 'GBP'; // Australischer Dollar
    }

    if ( baseRegion === 'NO' ) {
      return 'NOK'; // Norwegische Kronen
    }

    return locale && locale.includes('-')
      ? isoToCurrency(locale)
      : isoToCurrency(appConfig.defaultLocale)
  },
  unitSystem: (_state, getters) => {
    return appConfig.regions.find((r) => r.id === getters.region)!.units
  },
  socials: (_state, getters) => {
    return (
      (appConfig.socialMedia as Record<string, Record<string, string>>)[
        getters.region === 'US' ? 'en-US' : getters.language
        ] || {}
    )
  },
  baseRegion: (state) => state.i18n.baseRegion, // Getter für den baseIso im i18n-Objekt
  isInitialized: (state) => state.i18n.isInitialized, // Getter für Initialisierung
}

export const mutations: MutationTree<RootState> = {
  INIT_LOCALE_SSR(state, locale) {
    state.i18n.locale = locale
  },
  SHOW_CHANGE_LOCALE(state, open) {
    state.i18n.showChangeLocale = open
  },
  SET_BASE_REGION(state, baseIso: string) {
    state.i18n.baseRegion = baseIso // Separate Mutation, um baseIso zu setzen
  },
  SET_INITIALIZED(state, initialized: boolean) {
    state.i18n.isInitialized = initialized;
  },
}

export const actions: ActionTree<RootState, RootState> = {
  reload(_, iso: string) {
    window.location.replace(this.switchLocalePath(isoToSlug(iso)))
  },
  changeLocale({ commit }, open: boolean) {
    commit('SHOW_CHANGE_LOCALE', open)
  },
  setBaseRegion({ commit }, baseRegion: string) {
    commit('SET_BASE_REGION', baseRegion) // Separate Action, um baseIso zu aktualisieren
  },
  setInitialized({ commit }, initialized: boolean) {
    commit('SET_INITIALIZED', initialized) // Separate Action, um initialized zu aktualisieren
  },
  nuxtServerInit(_) {},
}
