import { gql } from 'graphql-tag'
import { Shopify } from '../types'
import config from '~/app.config'

export const fragment = gql`
  fragment Cart on Cart {
    id
    checkoutUrl
    attributes {
      key
      value
    }
    lines(first: 50) {
      edges {
        node {
          id
          quantity
          attributes {
            key
            value
          }
          discountAllocations {
            ... on CartAutomaticDiscountAllocation {
              title
            }
            discountedAmount {
              amount
              currencyCode
            }
          }
          estimatedCost {
            totalAmount {
              amount
              currencyCode
            }
          }
          merchandise {
            ... on ProductVariant {
              id
              sku
              title
              quantityAvailable
              product {
                handle
                title
                configuratorSlug: metafield(
                  namespace: "configurator"
                  key: "slug"
                ) {
                  value
                }
              }
              selectedOptions {
                name
                value
              }
              deliveryDays: metafield(namespace: "delivery", key: "days") {
                value
              }
              image {
                ... on Image {
                  jpg: url(
                    transform: {
                      maxWidth: 160
                      maxHeight: 160
                      scale: 1
                      preferredContentType: JPG
                    }
                  )
                  jpg2x: url(
                    transform: {
                      maxWidth: 160
                      maxHeight: 160
                      scale: 2
                      preferredContentType: JPG
                    }
                  )
                  webp: url(
                    transform: {
                      maxWidth: 160
                      maxHeight: 160
                      scale: 1
                      preferredContentType: WEBP
                    }
                  )
                  webp2x: url(
                    transform: {
                      maxWidth: 160
                      maxHeight: 160
                      scale: 2
                      preferredContentType: WEBP
                    }
                  )
                }
              }
            }
          }
        }
      }
    }
    estimatedCost {
      subtotalAmount {
        amount
        currencyCode
      }
      totalAmount {
        amount
        currencyCode
      }
    }
  }
`
export type ResponseType = {
  id: string
  checkoutUrl: string
  attributes: Shopify.Attribute[]
  lines: {
    id: string
    quantity: number
    attributes: Shopify.Attribute[]
    discountAllocations: {
      title?: string
      discountedAmount: Shopify.Amount
    }[]
    estimatedCost: {
      totalAmount: Shopify.Amount
    }
    merchandise: {
      id: string
      sku: string
      title: string
      quantityAvailable: number
      product: {
        handle: string
        title: string
        configuratorSlug?: { value: string }
      }
      selectedOptions: {
        name: string
        value: string
      }[]
      deliveryDays: number
      image: {
        jpg: string
        jpg2x: string
        webp: string
        webp2x: string
      } | null
    }
  }[]
  estimatedCost: {
    subtotalAmount: Shopify.Amount
    totalAmount: Shopify.Amount
  }
}

export const unwrapResponse = (cart: any): ResponseType => {
  const result = { ...cart }
  if (cart.lines) {
    result.lines = cart.lines.edges.map((obj: any) => {
      const line = obj.node
      line.merchandise.deliveryDays = Number(
        obj.node.merchandise.quantityAvailable < 1
          ? obj.node.merchandise.deliveryDays?.value ||
              config.productDeliveryDaysFallback
          : config.productDeliveryDaysFallback
      )
      return line
    })
  }
  return result
}
