

















import Vue, { PropType } from 'vue'
import { Portal } from 'portal-vue'
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'

let lastFocus: Element | null

export default Vue.extend({
  name: 'BSGOverlay',
  components: { Portal },
  model: {
    prop: 'open',
    event: 'change',
  },
  props: {
    name: {
      type: String as PropType<string>,
      required: true,
    },
    open: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    /* prevents background or escape close */
    blocking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    backgroundAnime: {
      type: String as PropType<string | object | [string, object]>,
      default: '',
    },
  },
  data() {
    return { drag: false }
  },
  watch: {
    open: {
      immediate: true,
      handler(isOpen: boolean) {
        if (isOpen) {
          // Delegate focus to overlay
          lastFocus = document.activeElement
          this.$nextTick(() => {
            const overlay = this.$refs.overlay as HTMLElement
            overlay.setAttribute('tabindex', '0')
            overlay.focus()
          })
          disableBodyScroll(this.$slots.default as unknown as HTMLElement, {
            allowTouchMove: () => true,
          })
        } else {
          // Remove focus from modal
          if (lastFocus) (lastFocus as HTMLElement).focus()
          setTimeout(() =>
            enableBodyScroll(this.$slots.default as unknown as HTMLElement)
          )
        }
      },
    },
  },
  mounted() {
    // Dismiss overlay by keypress
    window.addEventListener('keydown', this.keyHandler)
    // Only allow focus elements inside overlay
    window.addEventListener('focus', this.focusHandler, true)

    // Drag detection
    const thumbSize = 6
    let startX: number
    let startY: number

    window.addEventListener('mousedown', (event: MouseEvent) => {
      startX = event.clientX
      startY = event.clientY
    })

    window.addEventListener('mouseup', (event: MouseEvent) => {
      const diffX = Math.abs(event.clientX - startX)
      const diffY = Math.abs(event.clientY - startY)
      this.drag = !(diffX < thumbSize && diffY < thumbSize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keyHandler)
    window.removeEventListener('focus', this.focusHandler)
    if (this.open)
      enableBodyScroll(this.$slots.default as unknown as HTMLElement)
  },
  methods: {
    close() {
      if (!this.blocking) {
        this.$emit('change', false)
      }
    },
    closeOverlay() {
      if (!this.drag) this.close()
    },
    focusHandler(event: Event) {
      if (this.$refs.overlay) {
        const overlay = this.$refs.overlay as HTMLElement
        const target = event.target as Node | Window
        if (this.open && target instanceof Node && !overlay.contains(target)) {
          event.stopPropagation()
          overlay.focus()
        }
      }
    },
    keyHandler(event: KeyboardEvent) {
      if (event.code === 'Escape') this.close()
    },
  },
})
