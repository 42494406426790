





import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'BSGLogo',
  props: {
    mark: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    negative: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      logo: undefined as undefined | Promise<unknown>,
    }
  },
  computed: {
    logoName(): string {
      return `${this.mark ? 'mark' : 'type'}${this.negative ? '-negative' : ''}`
    },
    src(): string {
      return `/logos/${this.logoName}.svg`
    },
  },
})
