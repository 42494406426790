
















































import Vue from 'vue'
import {
  isoToSlug,
  makeIso,
  regions,
  regionsToDefaultLanguages,
  regionsToLanguages,
} from '~/services/i18n'

export default Vue.extend({
  name: 'CommonRegionLanguageSelector',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      regions,
      selected: {
        region: this.$vuex.getters.region,
        language: this.$vuex.getters.language,
      },
    }
  },
  computed: {
    languages(): string[] {
      return regionsToLanguages[this.selected.region]
    },
    defaultLanguage(): string {
      return regionsToDefaultLanguages[this.selected.region]
    },
  },
  watch: {
    'selected.region': {
      handler() {
        this.selected.language = this.defaultLanguage
      },
    },
  },
  methods: {
    onChangeModalState(open: boolean) {
      this.$emit('change', open)
    },
    async changeLocale() {
      const iso = makeIso(this.selected.region, this.selected.language)
      const userSlug = isoToSlug(iso)
      await this.$vuex.commit('INIT_LOCALE_SSR', userSlug)
      await this.$vuex.dispatch('cart/get')
      await this.$router.push(this.localePath(this.$route.path, userSlug))
      this.$emit('change', false)
    },
  },
})
