


































import Vue, { PropType } from 'vue'
import BSGLink from '../components/Link.vue'
import { NavBarItem } from './NavBar.vue'
import { enumValidator } from '~/services/enum-props'

export default Vue.extend({
  name: 'PatternNavBarLinks',
  components: {
    BSGLink,
  },
  props: {
    items: {
      type: Array as PropType<NavBarItem[]>,
      required: true,
    },
    layout: {
      type: String as PropType<string>,
      default: 'overlay',
      validator: enumValidator([
        'overlay',
        'desktop-root',
        'desktop-layer',
        'account',
      ]),
    },
  },
  methods: {
    hide() {
      this.$emit('hide')
    },
    isActive(item: NavBarItem) {
      const name = this.$route.name?.split('___')[0] || ''
      if (item.to === 'shop' && ['shop', 'product-handle'].includes(name))
        return true
      if (item.to === 'magazine' && name.startsWith('magazine')) return true
      if (item.to === 'benefits' && name.startsWith('benefits')) return true
      return (
        typeof item.to === 'string' &&
        !item.to.startsWith('https') &&
        this.$route.path ===
          (this.$routerI18n && this.$routerI18n.localePath(item.to))
      )
    },
  },
})
