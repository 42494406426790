import { Plugin } from '@nuxt/types'
import cookie from 'js-cookie'
import appConfig from '~/app.config'

/**
 * TODO: REWRITE!!!
 * - we need not only one cookie, we need a cookie for every region because of different cart lifetimes!
 * - update and delete cookie correctly (only if state.cart.shop changes, not based on mutation names and payloads)
 * - remove cookie also if user navigates clientside to the cart, not only on refresh!
 */
const vuexPersitedState: Plugin = ({ store }) => {
  store.subscribe(async (mutation, state) => {
    // get cart id from cookie and save it to store
    if (mutation.type === 'cart/loading' && mutation.payload === true) {
      const cartIds = cookie.get(appConfig.cookies.cart.name)
      if (cartIds) {
        store.commit('cart/updateCartId', JSON.parse(cartIds))
      }
    }

    // save cart id to cookie
    if (
      mutation.type === 'cart/update' &&
      !mutation.payload.doNotUpdateCookie
    ) {
      cookie.set(appConfig.cookies.cart.name, JSON.stringify(state.cart.shop), {
        expires: appConfig.cookies.cart.lifetimeDays,
        secure: appConfig.cookies.secure,
      })
    }

    // remove cookie on vuex clear
    if (mutation.type === 'cart/clear') {
      cookie.remove(appConfig.cookies.cart.name)
    }

    // get tokens from cookie and use it to log in
    if (mutation.type === 'auth/load') {
      const token = cookie.get(appConfig.cookies.authToken.name)
      const refreshToken = cookie.get(appConfig.cookies.authRefresh.name)
      if (token || refreshToken) {
        await store.dispatch('auth/loadLogin', { token, refreshToken })
        if (!state.auth.tokenContent) {
          if (refreshToken)
            try {
              await store.dispatch('auth/refresh', { refreshToken })
            } catch {
              store.dispatch('auth/logout')
            }
          else store.dispatch('auth/logout')
        }
      }
      await Promise.all([
        store.dispatch('user/getVideoStars'),
        store.dispatch('user/getActivePlan'),
      ])
      store.commit('auth/finishLoad')
    }

    // save tokens to cookie
    if (mutation.type === 'auth/login') {
      cookie.set(appConfig.cookies.authToken.name, state.auth.token, {
        expires: appConfig.cookies.authToken.lifetimeDays,
        secure: appConfig.cookies.secure,
      })
      cookie.set(appConfig.cookies.authRefresh.name, state.auth.refreshToken, {
        expires: appConfig.cookies.authRefresh.lifetimeDays,
        secure: appConfig.cookies.secure,
      })
    }
    if (mutation.type === 'auth/logout') {
      cookie.remove(appConfig.cookies.authToken.name)
      cookie.remove(appConfig.cookies.authRefresh.name)
    }
  })
}

export default vuexPersitedState
