import { slugToIso, isoToRegion, isoToLanguage, isoToCurrency } from './i18n'

export const _siteData = (
  locale: string
): { region: string; language: string; currency: string } => {
  if (locale === 'unknown') {
    return { region: 'unknown', language: 'unknown', currency: 'unknown' }
  } else {
    return {
      region: isoToRegion(slugToIso(locale)),
      language: isoToLanguage(slugToIso(locale)),
      currency: isoToCurrency(slugToIso(locale)),
    }
  }
}

export const trackEvent = (
  locale: string,
  event: string,
  optionalFields: Record<string, string | number | string[] | number[]>
): void => {
  const dataLayer = (window.dataLayer = window.dataLayer || [])
  // FUTURE: Handle special fields like 'value' before they are passed to tracking
  if (optionalFields.value && typeof optionalFields.value === 'string')
    optionalFields.value = parseFloat(optionalFields.value)
  const eventData = { ..._siteData(locale), event, ...optionalFields }
  dataLayer.push(eventData)
}
