import appConfig from '~/app.config'

export const getI18nSupportLink = (
  region: string,
  language: string
): string => {
  const regionConfig = appConfig.regions.find(({ id }) => id === region)
  if (!regionConfig) {
    // eslint-disable-next-line no-console
    console.error('Requested support link for non-existent region')
    return '/'
  }
  if (region === 'US') {
    return 'https://belliconusa.zendesk.com/hc/'
  }

  return regionConfig.customerSupport.to.replace('<LANGUAGE>', language)
}

export const getContactLink = (region: string, language: string): string => {
  const regionConfig = appConfig.regions.find(({ id }) => id === region)
  if (!regionConfig) {
    // eslint-disable-next-line no-console
    console.error('Requested contact link for non-existent region')
    return '/'
  }

  if (region === 'US') {
    return 'https://belliconusa.zendesk.com/hc/en-us/articles/15086200125714-Contact'
  }
  return (
    (regionConfig.customerSupport.phone as Record<string, string>)[language] ||
    getI18nSupportLink(region, language)
  )
}
