import { ResponseType as Cart } from '~/graphql/cart/cart'

export type ConfiguratorColor =
  | 'beige'
  | 'black'
  | 'white'
  | 'blue'
  | 'grey'
  | 'green'
  | 'orange'
  | 'pink'
  | 'red'
  | 'yellow'

export type ConfiguratorBungeeColors =
  | [ConfiguratorColor]
  | [ConfiguratorColor, ConfiguratorColor]
  | [ConfiguratorColor, ConfiguratorColor, ConfiguratorColor]
  | [
      ConfiguratorColor,
      ConfiguratorColor,
      ConfiguratorColor,
      ConfiguratorColor,
      ConfiguratorColor,
      ConfiguratorColor
    ]

export type ConfiguratorSize = 100 | 112 | 125
export type ConfiguratorFrame = 'eps' | 'stainless'
export type ConfiguratorLegs = 'screw' | 'fold'
export type ConfiguratorBar = 'none' | 'tbar' | 'support' | 'support2'
export type ConfiguratorMat = 'standard' | 'comfort'
export type ConfiguratorBungeeStrength = 1 | 2 | 3 | 4 | 5
export type ConfiguratorAssembly = 'yes' | 'no'

export type ConfiguratorConfiguration = {
  size: ConfiguratorSize
  frame: ConfiguratorFrame
  legs: ConfiguratorLegs
  bar: ConfiguratorBar
  mat: ConfiguratorMat
  matColor: ConfiguratorColor
  bungeeStrength: ConfiguratorBungeeStrength
  bungeeColors: ConfiguratorBungeeColors
  assembly?: ConfiguratorAssembly
  __uniqueId: string
  region?: string | null
}

export const isConfiguratorProduct = ({
  configuratorSlug,
}: Cart['lines'][0]['merchandise']['product']): boolean =>
  !!configuratorSlug &&
  /^bellicon-(100|112|125)-(eps|stainless)-(screw|fold)-(none|tbar|support|support2)$/.test(
    configuratorSlug.value
  )

export const rainbowColors: ConfiguratorBungeeColors = [
  'red',
  'orange',
  'yellow',
  'green',
  'blue',
  'pink',
]

export type ConfiguratorConfig = {
  presets: Record<string, ConfiguratorConfiguration>
}

export default {
  presets: {
    default: {
      size: 100,
      frame: 'eps',
      legs: 'screw',
      bar: 'none',
      mat: 'standard',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: ['grey'],
      assembly: 'no',
      __uniqueId: '',
    },
    '1': {
      size: 112,
      frame: 'eps',
      legs: 'fold',
      bar: 'none',
      mat: 'standard',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: ['orange'],
      __uniqueId: '',
    },
    '2': {
      size: 112,
      frame: 'eps',
      legs: 'fold',
      bar: 'none',
      mat: 'comfort',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: ['blue'],
      __uniqueId: '',
    },
    '3': {
      size: 112,
      frame: 'eps',
      legs: 'fold',
      bar: 'none',
      mat: 'comfort',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: ['black'],
      __uniqueId: '',
    },
    '4': {
      size: 112,
      frame: 'eps',
      legs: 'fold',
      bar: 'none',
      mat: 'standard',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: ['black'],
      __uniqueId: '',
    },
    '5': {
      size: 112,
      frame: 'eps',
      legs: 'screw',
      bar: 'none',
      mat: 'standard',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: ['red'],
      __uniqueId: '',
    },
    '6': {
      size: 112,
      frame: 'eps',
      legs: 'screw',
      bar: 'none',
      mat: 'comfort',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: ['grey'],
      __uniqueId: '',
    },
    '7': {
      size: 112,
      frame: 'eps',
      legs: 'fold',
      bar: 'none',
      mat: 'comfort',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: ['beige'],
      __uniqueId: '',
    },
    '8': {
      size: 112,
      frame: 'eps',
      legs: 'fold',
      bar: 'tbar',
      mat: 'standard',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: ['black'],
      __uniqueId: '',
    },
    '9': {
      size: 112,
      frame: 'eps',
      legs: 'fold',
      bar: 'tbar',
      mat: 'standard',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: ['black'],
      __uniqueId: '',
    },
    '10': {
      size: 112,
      frame: 'eps',
      legs: 'fold',
      bar: 'tbar',
      mat: 'comfort',
      matColor: 'green',
      bungeeStrength: 3,
      bungeeColors: ['black'],
      __uniqueId: '',
    },
    '11': {
      size: 112,
      frame: 'eps',
      legs: 'screw',
      bar: 'none',
      mat: 'comfort',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: ['pink'],
      __uniqueId: '',
    },
    '12': {
      size: 125,
      frame: 'eps',
      legs: 'fold',
      bar: 'tbar',
      mat: 'standard',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: ['black'],
      __uniqueId: '',
    },
    '13': {
      size: 125,
      frame: 'eps',
      legs: 'screw',
      bar: 'none',
      mat: 'comfort',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: ['black'],
      __uniqueId: '',
    },
    '14': {
      size: 125,
      frame: 'eps',
      legs: 'fold',
      bar: 'none',
      mat: 'standard',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: ['grey'],
      __uniqueId: '',
    },
    '15': {
      size: 125,
      frame: 'eps',
      legs: 'screw',
      bar: 'none',
      mat: 'standard',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: ['orange'],
      __uniqueId: '',
    },
    '16': {
      size: 100,
      frame: 'eps',
      legs: 'fold',
      bar: 'none',
      mat: 'comfort',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: ['blue', 'green'],
      __uniqueId: '',
    },
    '17': {
      size: 125,
      frame: 'eps',
      legs: 'screw',
      bar: 'tbar',
      mat: 'comfort',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: ['black'],
      __uniqueId: '',
    },
    '18': {
      size: 125,
      frame: 'eps',
      legs: 'fold',
      bar: 'tbar',
      mat: 'standard',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: ['blue'],
      __uniqueId: '',
    },
    '19': {
      size: 112,
      frame: 'stainless',
      legs: 'fold',
      bar: 'none',
      mat: 'comfort',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: ['grey'],
      __uniqueId: '',
    },
    '20': {
      size: 125,
      frame: 'stainless',
      legs: 'fold',
      bar: 'none',
      mat: 'standard',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: ['black'],
      __uniqueId: '',
    },
    '21': {
      size: 112,
      frame: 'stainless',
      legs: 'screw',
      bar: 'none',
      mat: 'comfort',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: ['red'],
      __uniqueId: '',
    },
    '22': {
      size: 112,
      frame: 'eps',
      legs: 'fold',
      bar: 'tbar',
      mat: 'standard',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: ['yellow'],
      __uniqueId: '',
    },
    '23': {
      size: 112,
      frame: 'eps',
      legs: 'fold',
      bar: 'tbar',
      mat: 'comfort',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: ['green'],
      __uniqueId: '',
    },
    '24': {
      size: 112,
      frame: 'eps',
      legs: 'fold',
      bar: 'tbar',
      mat: 'standard',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: ['grey'],
      __uniqueId: '',
    },
    '25': {
      size: 112,
      frame: 'eps',
      legs: 'screw',
      bar: 'tbar',
      mat: 'standard',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: ['grey'],
      __uniqueId: '',
    },
    '26': {
      size: 112,
      frame: 'eps',
      legs: 'screw',
      bar: 'none',
      mat: 'comfort',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: rainbowColors,
      __uniqueId: '',
    },
    '27': {
      size: 112,
      frame: 'eps',
      legs: 'screw',
      bar: 'none',
      mat: 'standard',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: rainbowColors,
      __uniqueId: '',
    },
    '28': {
      size: 112,
      frame: 'eps',
      legs: 'screw',
      bar: 'tbar',
      mat: 'standard',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: rainbowColors,
      __uniqueId: '',
    },
    '29': {
      size: 125,
      frame: 'eps',
      legs: 'fold',
      bar: 'tbar',
      mat: 'comfort',
      matColor: 'black',
      bungeeStrength: 3,
      bungeeColors: rainbowColors,
      __uniqueId: '',
    },
  },
} as ConfiguratorConfig
