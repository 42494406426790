import { gql } from 'graphql-tag'

export const query = gql`
  query variantBySku($sku: String!) {
    products(query: $sku, first: 100) {
      edges {
        node {
          variants(first: 100) {
            edges {
              node {
                sku
                quantityAvailable
              }
            }
          }
        }
      }
    }
  }
`

export type VariableType = { sku: string }

export type ResponseType =
  | { sku: string; quantityAvailable: number }[]
  | undefined

export const unwrapResponse = (response: any): ResponseType => {
  if (response.products) {
    return response.products.edges
      .map((productEdge: any) =>
        productEdge.node.variants.edges.map(
          (variantEdge: any) => variantEdge.node
        )
      )
      .reduce((acc: any[], arr: any[]) => acc.concat(arr), [])
  }
}
