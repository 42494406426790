import { ActionTree, MutationTree } from 'vuex'
import {
  pyramidTryPostVideoStars,
  pyramidTryDeleteVideoStars,
  PyramidGetVideoStarsResponse,
  pyramidGetVideoStars,
  PyramidGetPlanResponseContent,
  PyramidGetPlanWorkoutResponse,
  pyramidGetPlan,
  PyramidGetPlanResponse,
  pyramidGetPlanWorkout,
  pyramidDeletePlan,
  pyramidPostPlanWorkout,
} from '~/api/pyramid'
import { RootState } from '~/store'

export type UserState = {
  starredVideos: PyramidGetVideoStarsResponse
  activePlan: PyramidGetPlanResponseContent | undefined
  activePlanWorkouts: PyramidGetPlanWorkoutResponse
  isPlanCompleted: boolean
  totalTrainings: number
  finishedTrainings: number
}

export const state = () =>
  ({
    starredVideos: [],
    activePlan: undefined,
    activePlanWorkouts: [],
    isPlanCompleted: false,
    totalTrainings: 0,
    finishedTrainings: 0,
  } as UserState)

export const mutations: MutationTree<UserState> = {
  videoStars(state, data) {
    state.starredVideos = data
  },
  addVideoStars(state, uuid) {
    state.starredVideos.push(uuid)
  },
  removeVideoStars(state, uuid) {
    const index = state.starredVideos.findIndex((e) => e === uuid)
    if (index > -1) state.starredVideos.splice(index, 1)
  },
  activePlan(
    state,
    {
      plan,
      workout,
    }: {
      plan: PyramidGetPlanResponseContent | undefined
      workout: PyramidGetPlanWorkoutResponse
    }
  ) {
    state.activePlan = plan
    state.activePlanWorkouts = workout
    state.isPlanCompleted = false // Reset plan completion status when a new plan is set
  },
  markComplete(state, workoutUuid) {
    state.activePlanWorkouts = state.activePlanWorkouts.concat([
      {
        ctime: new Date().toString(),
        plan: state.activePlan!.plan,
        user: '',
        workout: workoutUuid,
      },
    ])
  },
  setPlanCompleted(state, isCompleted: boolean) {
    state.isPlanCompleted = isCompleted
  },
  updateTrainings(state, data: { totalTrainings: number; finishedTrainings: number }) {
    state.totalTrainings = data.totalTrainings
    state.finishedTrainings = data.finishedTrainings
  },
}

export const actions: ActionTree<UserState, RootState> = {
  async getVideoStars({ commit, dispatch, rootState }) {
    try {
      const { config, url } = pyramidGetVideoStars({
        locale: rootState.i18n.locale,
        token: rootState.auth.token!,
        userId: rootState.auth.tokenContent!.sub,
      })
      const response: { data: PyramidGetVideoStarsResponse } = await dispatch(
        'auth/tryAuthenticatedGet',
        { url, config },
        { root: true }
      )
      if (response && response.data) {
        commit('videoStars', response.data)
      } else {
        commit('videoStars', [])
      }
    } catch {
      // Neither token nor refresh; Stars empty
      commit('videoStars', [])
    }
  },
  async addVideoStars({ commit, rootState }, { that, uuid }) {
    if (rootState.auth.token && rootState.auth.tokenContent) {
      try {
        await pyramidTryPostVideoStars(that, {
          locale: rootState.i18n.locale,
          userId: rootState.auth.tokenContent.sub,
          uuid,
        })
        commit('addVideoStars', uuid)
      } catch (e) {
        // TODO: Handle errors
        console.error(e) // eslint-disable-line no-console
      }
    }
  },
  async removeVideoStars({ commit, rootState }, { that, uuid }) {
    if (rootState.auth.token && rootState.auth.tokenContent) {
      try {
        await pyramidTryDeleteVideoStars(that, {
          locale: rootState.i18n.locale,
          userId: rootState.auth.tokenContent.sub,
          uuid,
        })
        commit('removeVideoStars', uuid)
      } catch (e) {
        // TODO: Handle errors
        console.error(e) // eslint-disable-line no-console
      }
    }
  },
  async getActivePlan({ commit, rootState, dispatch }) {
    try {
      const { config, url } = await pyramidGetPlan({
        locale: rootState.i18n.locale,
        token: rootState.auth.token!,
      })
      const response: { data: PyramidGetPlanResponse } = await dispatch(
        'auth/tryAuthenticatedGet',
        { url, config },
        { root: true }
      )
      if (response && response.data && response.data[0]) {
        const { config, url } = await pyramidGetPlanWorkout({
          locale: rootState.i18n.locale,
          token: rootState.auth.token!,
          uuid: response.data[0].plan,
        })
        const workoutResponse: { data: PyramidGetPlanWorkoutResponse } =
          await dispatch(
            'auth/tryAuthenticatedGet',
            { url, config },
            { root: true }
          )
        commit('activePlan', {
          plan: response.data[0],
          workout: workoutResponse.data ?? [],
        })
      } else {
        commit('activePlan', { plan: undefined, workout: [] })
      }
    } catch (e) {
      // Neither token nor refresh; Plan empty
      commit('activePlan', { plan: undefined, workout: [] })
    }
  },
  async setActivePlan(
    { commit, rootState, dispatch },
    planInfo: PyramidGetPlanResponseContent
  ) {
    const plan = {
      ...planInfo,
      ctime: new Date().toString(),
      event: 'SUBSCRIBE',
    }
    const { config, url } = await pyramidGetPlanWorkout({
      locale: rootState.i18n.locale,
      token: rootState.auth.token!,
      uuid: plan.plan,
    })
    const workoutResponse: { data: PyramidGetPlanWorkoutResponse } =
      await dispatch(
        'auth/tryAuthenticatedGet',
        { url, config },
        { root: true }
      )
    commit('activePlan', {
      plan,
      workout: workoutResponse.data ?? [],
    })
  },
  async unsetActivePlan({ commit, state, rootState, dispatch }) {
    const { config, url } = await pyramidDeletePlan({
      locale: rootState.i18n.locale,
      token: rootState.auth.token!,
      uuid: state.activePlan!.plan,
    })
    const response = await dispatch(
      'auth/tryAuthenticatedDelete',
      { url, config },
      { root: true }
    )
    if (response.status === 204)
      commit('activePlan', { plan: undefined, workout: [] })
  },
  async markVideoCompleted({ commit, state, rootState, dispatch }, workout) {
    const { url, data, config } = pyramidPostPlanWorkout({
      locale: rootState.i18n.locale,
      token: rootState.auth.token!,
      plan: state.activePlan!.plan,
      workout,
    })
    const response = await dispatch(
      'auth/tryAuthenticatedPost',
      { url, data, config },
      { root: true }
    )
    if (response.status === 201) commit('markComplete', workout)
  },
  checkPlanCompletion({ commit, state }) {
    const isCompleted = state.totalTrainings === state.finishedTrainings;
    commit('setPlanCompleted', isCompleted);
  },
  handleUpdateTrainings({ commit, dispatch }, data: { totalTrainings: number, finishedTrainings: number }) {
    commit('updateTrainings', data);
    dispatch('checkPlanCompletion');
  },
}
