













































import Vue, { PropType } from 'vue'
import BSGOverlay from './Overlay.vue'
import BSGButton from './Button.vue'
import BSGHeadline from './Headline.vue'

export default Vue.extend({
  name: 'BSGModal',
  components: { BSGOverlay, BSGButton, BSGHeadline },
  model: {
    prop: 'open',
    event: 'change',
  },
  props: {
    headline: { type: String as PropType<string>, required: true },
    overline: { type: String as PropType<string>, default: undefined },
    open: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    blocking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    step: { type: Number as PropType<number>, default: -1 },
  },
  methods: {
    close() {
      this.$emit('change', false)
    },
  },
})
