import appConfig from '~/app.config'

export const trustedShopsInit = function (region: string): void {
  if (!['CH', 'EU'].includes(region)) return

  const id =
    region === 'CH' ? appConfig.trustedShops.CH : appConfig.trustedShops.EU

  const ts = document.createElement('script')
  ts.async = true
  ts.setAttribute('data-desktop-y-offset', '40')
  ts.setAttribute('data-mobile-y-offset', '220')
  ts.setAttribute('data-desktop-disable-reviews', 'false')
  ts.setAttribute('data-desktop-enable-custom', 'false')
  ts.setAttribute('data-desktop-position', 'right')
  ts.setAttribute('data-desktop-custom-width', '156')
  ts.setAttribute('data-desktop-enable-fadeout', 'false')
  ts.setAttribute('data-disable-mobile', 'false')
  ts.setAttribute('data-disable-trustbadge', 'false')
  ts.setAttribute('data-mobile-custom-width', '58')
  ts.setAttribute('data-mobile-disable-reviews', 'true')
  ts.setAttribute('data-mobile-enable-custom', 'false')
  ts.setAttribute('data-mobile-position', 'left')
  ts.setAttribute('data-mobile-enable-topbar', 'false')
  ts.setAttribute('data-mobile-enable-fadeout', 'true')
  ts.setAttribute('charset', 'UTF-8')
  ts.setAttribute('src', `https://widgets.trustedshops.com/js/${id}.js`)

  ts.addEventListener('load', (_event) => {
    window.dispatchEvent(new CustomEvent('trustedShopsLoaded'))
  })

  document.head.appendChild(ts)
}

export const trustedShopsVisibility = (
  language: string,
  routeName?: string | null | undefined
): void => {
  function tryAgain() {
    if (!window.trustbadge) {
      setTimeout(tryAgain, 250)
      return
    }

    if (routeName?.startsWith('video') || language !== 'de') {
      trustedShopsClose()
    } else {
      trustedShopsOpen()
    }
  }
  tryAgain() // Polling until trusted shops is loaded
}

export const trustedShopsOpen = function (): void {
  try {
    if (window.trustbadge) window.trustbadge.reInitialize()
  } catch {}
}

export const trustedShopsClose = function (): void {
  try {
    if (window.trustbadge) window.trustbadge.remove()
  } catch {}
}
