import { gql } from 'graphql-tag'
import { Shopify } from '../types'
import {
  fragment,
  ResponseType as Cart,
  unwrapResponse as unwrapCart,
} from './cart'

export const query = gql`
  mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!, $lng: LanguageCode!)
  @inContext(language: $lng) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        ...Cart
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${fragment}
`

export type VariableType = {
  cartId: string
  lineIds: string[]
  lng: string
}

export type ResponseType =
  | {
      cart: Cart | undefined
      userErrors: Shopify.Error[]
    }
  | undefined

export const unwrapResponse = (response: any): ResponseType => {
  let cart
  let userErrors = []

  if (response.cart) {
    cart = unwrapCart(response.cart)
  }

  if (response.userErrors) {
    userErrors = response.userErrors
  }

  return { cart, userErrors }
}
