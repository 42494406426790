


















import Vue, { PropType } from 'vue'
import BSGLink from './../Link.vue'

export default Vue.extend({
  name: 'BSGCardSelectable',
  components: {
    BSGLink,
  },
  props: {
    to: {
      type: [String, Object, undefined] as PropType<
        string | object | undefined
      >,
      default: undefined,
    },
    title: {
      type: String as PropType<string>,
      default: '',
    },
    selected: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
})
