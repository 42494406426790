


































































import Vue, { PropType, VueConstructor } from 'vue'
import { BSGAccordionOptions } from './Accordion.vue'
import BSGIcon from './Icon.vue'
import { enumValidator } from '~/services/enum-props'
import { getColorNames, getColorStyle } from '~/styleguide/utils'

export type AccordionItem = {
  name: string
  expanded: boolean
}

type InjectedFields = {
  accordionRegister: (name: string, expanded: boolean) => void
  accordionUnregister: (name: string) => void
  accordionItems: AccordionItem[]
  accordionToggle: (name: string, expand: boolean) => void
  fetchOptions: () => BSGAccordionOptions
}

const VueWithInject = Vue as VueConstructor<Vue & InjectedFields>

export default VueWithInject.extend({
  name: 'BSGAccordionItem',
  components: {
    BSGIcon,
  },
  inject: {
    accordionRegister: 'accordionRegister',
    accordionUnregister: 'accordionUnregister',
    accordionItems: 'accordionItems',
    accordionToggle: 'accordionToggle',
    fetchOptions: 'fetchOptions',
  },
  props: {
    name: {
      type: String as PropType<string>,
      required: true,
    },
    startExpanded: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    icon: {
      type: String as PropType<string>,
      default: '',
    },
    color: {
      type: String as PropType<string>,
      default: '',
      validator: enumValidator(['', ...getColorNames()]),
    },
  },
  data() {
    return {
      hover: false,
    }
  },
  computed: {
    item(): AccordionItem {
      return this.accordionItems.find((item) => item.name === this.name)!
    },
    id(): number {
      return this.accordionItems.findIndex((item) => item.name === this.name)
    },
    isExpanded(): boolean {
      return !!this.item?.expanded
    },
    options(): BSGAccordionOptions {
      return this.fetchOptions()
    },
    colorStyle(): Partial<CSSStyleDeclaration> {
      if (!(this.color || this.options.color)) return {}
      return !this.isExpanded
        ? getColorStyle(this.color || this.options.color, 'color')
        : {}
    },
    bgColorStyle(): Partial<CSSStyleDeclaration> {
      if (!(this.color || this.options.color)) return {}
      return this.isExpanded
        ? getColorStyle(this.color || this.options.color, 'background-color')
        : { backgroundColor: 'rgb(255, 255, 255)' }
    },
  },
  methods: {
    toggle() {
      this.accordionToggle(this.name, !this.isExpanded)
    },
  },
  created() {
    this.accordionRegister(this.name, this.startExpanded)
  },
  beforeDestroy() {
    this.accordionUnregister(this.name)
  },
})
