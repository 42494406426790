import { NuxtAppOptions } from '@nuxt/types'
import { ErrorResponse } from 'apollo-link-error'
import { isoToApolloClient, isoToLanguage, slugToIso } from './i18n'
type ApolloContext = { headers: Record<string, string> }


export const getApolloContext = (locale: string): ApolloContext => ({
  headers: {
    'Accept-Language': isoToLanguage(locale),
  },
})

export const getApolloUri = (shop: string, endpoint: string): string => {
  return `${endpoint}`.replace('<SHOP>', shop!) as string
}

export const getApolloHeaders = (
  accessToken: string
): Record<string, string> => {
  return {
    'X-Shopify-Storefront-Access-Token': `${accessToken}`,
  }
}

export const smartQueryScaffold = <QueryType, ResponseType>(
  locale: string,
  query: QueryType,
  unwrapResponse: (raw: unknown) => ResponseType,
): {
  prefetch: boolean;
  query: QueryType;
  client: string;
  update: (raw: unknown) => ResponseType;
  errorPolicy: string;
  error({ graphQLErrors, networkError, operation, message }: (ErrorResponse & { message: string })): void
} => {

  return {
    client: isoToApolloClient(slugToIso(locale)),
    prefetch: false,
    query,
    update: unwrapResponse,
    errorPolicy: 'all',
    error({
            graphQLErrors,
            networkError,
            operation,
            message,
          }: ErrorResponse & { message: string }) {
      if (graphQLErrors) {
        // eslint-disable-next-line no-console
        console.error(graphQLErrors)
        if (operation) {
          // eslint-disable-next-line no-console
          console.error(operation)
          // eslint-disable-next-line no-console
          console.error(
            `x-request-id: ${operation
              .getContext()
              .response.headers.get('x-request-id')}`
          )
        }
      }

      // eslint-disable-next-line no-console
      if (networkError) console.error(`Network error: ${networkError}`)

      throw message
    },
  }
}


type ApolloProvider = NonNullable<NuxtAppOptions['apolloProvider']>
type QueryOptions = Parameters<ApolloProvider['clients'][0]['query']>[0]

export const makeApolloRequest = async <ResponseType>(
  apolloProvider: ApolloProvider,
  locale: string,
  unwrapResponse: (raw: unknown) => ResponseType,
  options: QueryOptions
): Promise<ResponseType> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const clientKey = isoToApolloClient(slugToIso(locale))

    if (!apolloProvider.clients[clientKey]) {
      throw new Error(`No Apollo Client found for key: ${clientKey}`)
    }
    const response = await apolloProvider.clients[clientKey].query(options)
    const unwrapped = unwrapResponse(response.data)
    return unwrapped
  } catch (error) {
    throw error // Re-throw the error after logging it
  }
}
