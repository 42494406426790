import Vue from 'vue'
import { MetaInfo } from 'vue-meta'
import appConfig from '~/app.config'
import { isos, isoToSlug } from '~/services/i18n'

export const isoToHrefLang = (iso: string): string => {
  if (appConfig.hrefLangMap[iso]) return appConfig.hrefLangMap[iso]
  else return iso.toLowerCase()
}

export const toAbsoluteUrl = (relative: string): string =>
  `${process.env.DOMAIN || window?.location.origin || ''}${relative}`

export function layoutHead(this: Vue): MetaInfo {
  const metaTagExcludePage = {
    hid: 'robots',
    name: 'robots',
    content: 'noindex, nofollow',
  }

  if (this.$route.name) {
    const routeName = this.$route.name.split('___')[0]
    let alternateTags = [] as { rel: string; href: string; hreflang: string }[]
    if (!this.$routerI18n.isDynamicPage(this.$route.name)) {
      alternateTags = [
        {
          rel: 'alternate',
          href: toAbsoluteUrl(
            this.$routerI18n.localePath(
              routeName,
              isoToSlug(appConfig.defaultLocale)
            )
          ),
          hreflang: 'x-default',
        },
        ...isos
          .filter(
            (iso) =>
              !appConfig.urlIsoBlacklist.some(
                (entry) =>
                  routeName === entry.page.replace(/\/index$/, '') &&
                  iso === entry.iso
              )
          )
          .map((iso) => ({
            rel: 'alternate',
            href: toAbsoluteUrl(
              this.$routerI18n.localePath(routeName, isoToSlug(iso))
            ),
            hreflang: isoToHrefLang(iso),
          })),
      ]
    }
    const link = [
      {
        rel: 'canonical',
        href: toAbsoluteUrl(
          this.$routerI18n.localePath(routeName, this.$i18n.locale)
        ),
      },
      ...alternateTags,
    ]
    const meta = []

    if (appConfig.seoUrlBlacklist.includes(routeName))
      meta.push(metaTagExcludePage)

    const head = {
      htmlAttrs: {
        lang: isoToHrefLang(this.$i18n.locale),
      },
      link,
      meta,
    }
    return head
  } else {
    return {
      htmlAttrs: {
        lang: isoToHrefLang(this.$i18n.locale),
      },
      meta: [metaTagExcludePage],
    }
  }
}

export const generatePageHead = (
  title: string,
  description: string
): MetaInfo => {
  return {
    title,
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: description,
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: title,
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: description,
      },
    ],
  }
}

type DynamicData =
  | {
      title?: string
      description?: string
      seo?: { title?: string; description?: string }
    }
  | undefined

export const generateDynamicPageHead = (
  data: DynamicData,
  defaultTitle: string,
  defaultDescription: string
): MetaInfo => {
  const title = data?.seo?.title || data?.title || defaultTitle
  const description =
    data?.seo?.description || data?.description || defaultDescription
  const head = generatePageHead(title, description)
  head.meta!.push({
    hid: 'robots',
    name: 'robots',
    content: data ? 'index, follow' : 'nofollow, noindex',
  })
  return head
}
