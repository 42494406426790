var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"pattern-nav-bar-link flex justify-start items-start my-8 md:my-12 lg:my-0",class:[("layout-" + _vm.layout)]},_vm._l((_vm.items),function(item,index){return _c('li',{key:((item.name) + "-" + index)},[_c('div',{staticClass:"link-container"},[_c('BSGLink',{class:{
          active: _vm.isActive(item),
        },attrs:{"to":(typeof item.to === 'string' || typeof item.to === 'object') &&
          !Array.isArray(item.to)
            ? item.to
            : undefined,"title":item.name},on:{"click":function () { return _vm.layout !== 'desktop-root'
              ? _vm.hide()
              : typeof item.to === 'function'
              ? item.to()
              : undefined; }}},[_vm._v("\n        "+_vm._s(item.name)+"\n      ")])],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }