import { Plugin } from '@nuxt/types'
import appConfig from '~/app.config'
import {
  regionsToLanguages,
  makeIso,
  isoToLanguage,
  isoToSlug,
} from '~/services/i18n'
import {
  CategoryList,
  BenefitsArticle,
  MagazineArticle,
  ArticleInterface,
  CategoryInterface,
  LandingPage,
  ContentType,
} from '~/content/types'
import { getVideoLocale } from '~/services/user-locale'

type ISO = string
type Language = string
type UID = string
type Slug = string
type Route = {
  name: string
  params: Record<string, string>
}

type RouterI18n = {
  contentRoutes: {
    benefits: {
      articles: Record<UID, Record<Language, Slug>>
      categories: Record<UID, Record<Language, Slug>>
      articlesToCategories: Record<UID, UID>
    }
    magazine: {
      articles: Record<UID, Record<Language, Slug>>
      categories: Record<UID, Record<Language, Slug>>
      articlesToCategories: Record<UID, UID>
    }
    landing: {
      pages: Record<UID, Record<Language, Slug>>
    }
  }
  localePath: (route: string | object, locale?: string) => string
  isDynamicPage: (route: string) => boolean
  getRouteParams: (
    params:
      | {
          type: 'landing'
          articleUid: UID
        }
      | {
          type: 'benefits' | 'magazine'
          categoryUid: UID
          articleUid: UID
        }
  ) => void
}

declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Vue {
    $routerI18n: RouterI18n
  }
}

declare module '@nuxt/types' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface NuxtAppOptions {
    $routerI18n: RouterI18n
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Context {
    $routerI18n: RouterI18n
  }
}

/**
 * Central uid to language slug mapping for articles
 */
const generateArticleMapping = (articles: ArticleInterface[]) => {
  return articles.reduce((acc, { uid, slug, language }) => {
    if (!acc[uid]) acc[uid] = {}
    acc[uid][language] = slug
    return acc
  }, {} as Record<UID, Record<Language, Slug>>)
}

/**
 * Central handle to language slug mapping for categories
 */
const generateCategoryMapping = (categories: CategoryList[]) => {
  return categories.reduce((acc, { language, items }) => {
    ;(items as CategoryInterface[]).forEach((item) => {
      if (!acc[item.uid]) acc[item.uid] = {}
      acc[item.uid][language] = item.slug
    })
    return acc
  }, {} as Record<UID, Record<Language, Slug>>)
}

/**
 * Central article.uid to categories.uid mapping
 */
const generateArticleToCategoriesMapping = (
  articles: (BenefitsArticle | MagazineArticle)[]
) => {
  return articles.reduce((acc, { uid, category }) => {
    acc[uid] = category
    return acc
  }, {} as Record<UID, UID>)
}

/**
 * Fetch all dynamic content routes initially and generate mappings
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const generateContentRoutes = async ($content: any) => {
  const benefitsCategoriesPromise = $content('benefits/categories').fetch()
  const benefitsArticlesPromise = $content('benefits/articles', {
    deep: true,
  }).fetch()
  const magazineCategoriesPromise = $content('magazine/categories').fetch()
  const magazineArticlesPromise = $content('magazine/articles', {
    deep: true,
  }).fetch()
  const landingPagesPromise = $content('landing/pages', {
    deep: true,
  }).fetch()

  const benefitsCategories =
    (await benefitsCategoriesPromise) as unknown as CategoryList[]
  const benefitsArticles =
    (await benefitsArticlesPromise) as unknown as BenefitsArticle[]
  const magazineCategories =
    (await magazineCategoriesPromise) as unknown as CategoryList[]
  const magazineArticles =
    (await magazineArticlesPromise) as unknown as MagazineArticle[]
  const landingPages = (await landingPagesPromise) as unknown as LandingPage[]

  return {
    benefits: {
      articles: generateArticleMapping(benefitsArticles),
      categories: generateCategoryMapping(benefitsCategories),
      articlesToCategories:
        generateArticleToCategoriesMapping(benefitsArticles),
    },
    magazine: {
      articles: generateArticleMapping(magazineArticles),
      categories: generateCategoryMapping(magazineCategories),
      articlesToCategories:
        generateArticleToCategoriesMapping(magazineArticles),
    },
    landing: {
      articles: generateArticleMapping(landingPages),
      categories: undefined,
      articlesToCategories: undefined,
    },
  }
}

const isRoute = (r: Route | string): r is Route => !!(r as Route).name

const routerI18n: Plugin = async (
  { $content, localePath: nuxtLocalePath, i18n },
  inject
) => {
  const contentRoutes = await generateContentRoutes($content)

  const isDynamicPage = (routeName: string) => {
    const name = routeName.split('___')[0]
    const pages = [
      'benefits-category-slug',
      'magazine-category-slug',
      'landing-slug',
    ]
    return pages.includes(name)
  }

  /**
   * Central reimplementation of i18n's localPath to use simple uids
   * to create links for dynamic content detail pages.
   * Also changes video platform links to only use available video locales.
   */
  const localePath = (route: Route | string, locale?: ISO) => {
    if (!locale) locale = i18n.locale
    if (locale === 'unknown') locale = appConfig.defaultLocale
    if (isRoute(route) && isDynamicPage(route.name)) {
      const uid = route.params.uid
      if (!uid) throw new Error(`Missing uid for dynamic page ${route.name}!`)
      const type = route.name.split('-')[0] as ContentType
      const mapping = contentRoutes[type]

      const language = isoToLanguage(locale)

      let dynamicRoute
      const articleSlug = mapping.articles[uid][language]
      if (!mapping.categories) {
        dynamicRoute = {
          name: route.name,
          params: {
            slug: articleSlug,
          },
        }
      } else {
        const categoryUid = mapping.articlesToCategories[uid]
        const categorySlug = mapping.categories[categoryUid][language]

        dynamicRoute = {
          name: route.name,
          params: {
            category: categorySlug,
            slug: articleSlug,
          },
        }
      }

      return nuxtLocalePath(dynamicRoute, isoToSlug(locale))
    } else if (
      (isRoute(route) && route.name.startsWith('video')) ||
      (!isRoute(route) && route.startsWith('video'))
    ) {
      return nuxtLocalePath(route, getVideoLocale(isoToSlug(locale)))
    } else {
      return nuxtLocalePath(route, isoToSlug(locale))
    }
  }

  /**
   * Prepare category and article slugs for all regions and languages
   * This params are needed for dynamic content detail pages
   * See: https://i18n.nuxtjs.org/lang-switcher/
   */
  const getRouteParams = ({
    type,
    categoryUid,
    articleUid,
  }: {
    type: ContentType
    categoryUid?: UID
    articleUid: UID
  }) => {
    const mapping = contentRoutes[type]
    const articleSlugs = mapping.articles[articleUid]
    const categorySlugs =
      mapping.categories && categoryUid
        ? mapping.categories[categoryUid]
        : undefined

    const i18nRouteParams = {} as Record<
      string,
      { category?: Slug; slug: Slug }
    >
    Object.keys(regionsToLanguages).forEach((region: string) => {
      regionsToLanguages[region].forEach((language) => {
        const slug = isoToSlug(makeIso(region, language))
        i18nRouteParams[slug] = categorySlugs
          ? {
              slug: articleSlugs[language],
              category: categorySlugs[language],
            }
          : { slug: articleSlugs[language] }
      })
    })
    return i18nRouteParams
  }

  inject('routerI18n', {
    contentRoutes,
    localePath,
    isDynamicPage,
    getRouteParams,
  })
}

export default routerI18n
