import { render, staticRenderFns } from "./NavBarFooter.vue?vue&type=template&id=78682020&scoped=true&"
import script from "./NavBarFooter.vue?vue&type=script&lang=ts&"
export * from "./NavBarFooter.vue?vue&type=script&lang=ts&"
import style0 from "./NavBarFooter.vue?vue&type=style&index=0&id=78682020&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78682020",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BSGButton: require('/workspace/styleguide/components/Button.vue').default})
