import { render, staticRenderFns } from "./Modal.vue?vue&type=template&id=e620fe2c&"
import script from "./Modal.vue?vue&type=script&lang=ts&"
export * from "./Modal.vue?vue&type=script&lang=ts&"
import style0 from "./Modal.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BSGButton: require('/workspace/styleguide/components/Button.vue').default,BSGHeadline: require('/workspace/styleguide/components/Headline.vue').default,BSGOverlay: require('/workspace/styleguide/components/Overlay.vue').default})
