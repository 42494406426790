
import Vue from 'vue'
import { Plugin } from '@nuxt/types'
import appConfig from '~/app.config'

interface TracifyOptions {
  region: string
}

interface Tracify {
  capturePurchase: (params: {
    amount: string
    orderId: string
    currency: string
    dateTime: Date
  }) => void
  capturePageView: () => void
}

// Extend Vue type definitions
declare module 'vue/types/vue' {
  interface Vue {
    $tracify: Tracify
    $loadTracifyScripts: (region: string) => void;
  }
}

// Erweiterung der Window-Typdefinition
interface TracifyConstructor {
  new (options: { csid: string; fingerPrint: boolean }): Tracify
}

declare global {
  interface Window {
    Tracify?: TracifyConstructor
  }
}



// Extend Nuxt Context
declare module '@nuxt/types' {
  interface Context {
    $tracify: Tracify
    $loadTracifyScripts: (region: string) => void
  }
}

// Function to dynamically load and remove scripts
function loadTracifyScripts(region: string): void {
  if (process.client) {
    const CSID = (appConfig.tracify as Record<string, string>)[region]

    if (!CSID) {
      console.error(`No CSID found for region: ${region}`)
      return
    }

    const loadScript = (
      id: string,
      src: string,
      callback?: () => void
    ): void => {
      const existingScript = document.getElementById(id)
      if (existingScript) {
        document.head.removeChild(existingScript)
      }
      const script = document.createElement('script')
      script.id = id
      script.async = true
      script.src = src
      if (callback) script.onload = callback
      document.head.appendChild(script)
    }

    // Load the Tracify v2 script
    loadScript(
      'tracify-v2-script',
      `https://scripting.tracify.ai/tracifyv2.js?csid=${CSID}`,
      () => {
        window.dispatchEvent(new CustomEvent('tracify-loaded'))
      }
    )

    // Load the Tracify API script
    loadScript(
      'tracify-api-script',
      'https://scripting.tracify.ai/api.v1.js',
      () => {
        if (typeof window.Tracify === 'function') {
          Vue.prototype.$tracify = new window.Tracify({
            csid: '7378385c-566b-469b-a1eb-2e38d3c37ab7',
            fingerPrint: true,
          })
          Vue.prototype.$tracify.capturePageView()
        }
        window.dispatchEvent(new CustomEvent('tracify-api-loaded'))
      }
    )
  }
}

// Nuxt plugin integration
const tracifyPlugin: Plugin = (context, inject) => {
  inject('loadTracifyScripts', loadTracifyScripts);
}

export default tracifyPlugin
