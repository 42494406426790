









import Vue, { PropType } from 'vue'
import { AccordionItem } from './AccordionItem.vue'
import { enumValidator } from '~/services/enum-props'
import { getColorNames } from '~/styleguide/utils'

export type BSGAccordionOptions = {
  colorTheme: string
  icon: string
  color: string
}

type AccordionProvide = {
  accordionRegister: (name: string, expanded: boolean) => void
  accordionUnregister: (name: string) => void
  accordionItems: AccordionItem[]
  accordionToggle: (name: string, expand: boolean) => void
  fetchOptions: () => BSGAccordionOptions
}

export default Vue.extend({
  name: 'BSGAccordion',
  provide(): AccordionProvide {
    return {
      accordionRegister: this.accordionRegister,
      accordionUnregister: this.accordionUnregister,
      accordionItems: this.items,
      accordionToggle: this.accordionToggle,
      fetchOptions: this.getOptions,
    }
  },
  props: {
    multiExpand: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    colorTheme: {
      type: String as PropType<string>,
      default: 'light',
      validator: enumValidator(['light', 'dark']),
    },
    icon: {
      type: String as PropType<string>,
      default: '',
    },
    color: {
      type: String as PropType<string>,
      default: '',
      validator: enumValidator(['', ...getColorNames()]),
    },
  },
  data() {
    return { items: [] as AccordionItem[] }
  },
  methods: {
    accordionRegister(name: string, expanded: boolean) {
      this.items.push({
        name,
        expanded,
      })
    },
    accordionUnregister(name: string) {
      const index = this.items.findIndex((item) => item.name === name)
      if (index === -1) return
      this.items.splice(index, 1)
    },
    accordionToggle(name: string, expanded: boolean) {
      const index = this.items.findIndex((item) => item.name === name)
      if (index === -1) return
      this.items.splice(index, 1, {
        name,
        expanded,
      })
      if (!this.multiExpand) {
        while (true) {
          const exp = this.items.find(
            (item) => item.name !== name && item.expanded
          )
          if (!exp) return
          const index = this.items.findIndex((i) => i.name === exp.name)
          this.items.splice(index, 1, {
            name: exp.name,
            expanded: false,
          })
        }
      }
    },
    getOptions(): BSGAccordionOptions {
      return {
        colorTheme: this.colorTheme,
        icon: this.icon,
        color: this.color,
      }
    },
  },
})
