import { Plugin } from '@nuxt/types'
import { convert as cvt, Unit } from 'convert'
import appConfig from '~/app.config'

type UnitMap = Record<string, { convert: Unit; format: string }>

type Convert = {
  convert: typeof cvt
  display: (
    value: number,
    unit: keyof UnitMap,
    maximumFractionDigits?: number,
    language?: string,
    region?: string
  ) => string
}

declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Vue {
    $convert: Convert
  }
}

declare module '@nuxt/types' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface NuxtAppOptions {
    $convert: Convert
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Context {
    $convert: Convert
  }
}

/**
 *
 */
const convert: Plugin = ({ store }, inject) => {
  const unitMap = {
    mm: {
      convert: 'inch',
      format: 'millimeter',
    },
    cm: {
      convert: 'inch',
      format: 'centimeter',
    },
    m: {
      convert: 'inch',
      format: 'meter',
    },
    km: {
      convert: 'inch',
      format: 'kilometer',
    },
    g: {
      convert: 'lb',
      format: 'gram',
    },
    kg: {
      convert: 'lb',
      format: 'kilogram',
    },
  } as UnitMap

  inject('convert', {
    convert: cvt,
    /**
     * display units in correct metric for current region and language
     *
     * Examples:
     * - $convert.display(100, 'cm') -> 100 cm (metric) / 39″ (imperial)
     * - $convert.display(10, 'kg') -> 10 kg (metric) / 22 lb (imperial)
     * - $convert.display(100, 'cm', 2) -> 100,00 cm (metric) / 39.37″ (imperial)
     * - $convert.display(6.4, 'cm', 2) -> 6.4 cm (metric) / 2.52″ (imperial)
     * - $convert.display(100, 'cm', 2, 'de', 'EU') -> 100 cm (metric)
     *
     * @param value Value to format
     * @param unit Unit to display
     * @param maximumFractionDigits Decimal places
     * @param toImperialUnit Optional: If set, it take the given imperial unit instead of the best fit
     * @param language Optional: If set, it take the given language instead of the applications language
     * @param region Optional: If set, it take the given region instead of the applications region
     * @returns
     */
    display: (
      value,
      unit,
      maximumFractionDigits = 0,
      language = store.getters.language,
      region = store.getters.region
    ) => {
      // get unit system from config
      const regionConfig = appConfig.regions.find((r) => r.id === region)

      // imperial system
      if (regionConfig?.units === 'imperial') {
        const imperialUnit = unitMap[unit].convert
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const imperialQuantity = cvt(value, unit as any).to(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          imperialUnit as any,
          'imperial'
        )
        // bellicon wants a custom format / shorthand for inch ...
        return `${Number(imperialQuantity).toFixed(maximumFractionDigits)}${
          imperialUnit === 'inch' ? '″' : ` ${imperialUnit}`
        }`
      } else {
        try {
          return new Intl.NumberFormat(language, {
            style: 'unit',
            unit: unitMap[unit].format,
            maximumFractionDigits,
          }).format(value) as string
        } catch {
          // Older Safari browsers don't support "style: 'unit'"
          return `${value.toFixed(0)} ${unit}`
        }
      }
    },
  } as Convert)
}

export default convert
