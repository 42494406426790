





















import Vue, { PropType } from 'vue'
import {
  FooterLink,
  Look as FooterLook,
  SocialLink,
} from 'styleguide/patterns/Footer.vue'
import { getContactLink, getI18nSupportLink } from '~/services/support'
import { slugToIso } from '~/services/i18n'
import appConfig from '~/app.config'

export default Vue.extend({
  name: 'CommonFooter',
  props: { look: { type: String as PropType<FooterLook>, required: true } },
  data() {
    return {
      renderComponent: true,
    }
  },
  watch: {
    '$i18n.locale': {
      handler() {
        // Neurendern der Komponente erzwingen
        this.renderComponent = false;
        this.$nextTick(() => {
          this.renderComponent = true;
        });
      },
      immediate: true,
    },
  },
  computed: {
    legalLinks(): FooterLink[] {
      return this.$vuex.getters.region === 'US'
        ? [
            {
              name: this.$t('LINK_TERMS'),
              to: 'terms-conditions',
            },
            {
              name: this.$t('LINK_PRIVACY'),
              to: 'privacy-policy',
            },
            {
              name: this.$t('LINK_SAFETY_INSTRUCTIONS'),
              to: 'safety-instructions',
            },
            {
              name: this.$t('LINK_COOKIE_SETTINGS'),
              to: () => {
                if (window.Cookiebot) window.Cookiebot.show()
              },
            },
            {
              name: this.$t('LINK_DISCLAIMER'),
              to: 'disclaimer',
            },
            {
              name: this.$t('LINK_CONTACT'),
              to: getContactLink(
                this.$vuex.getters.region,
                this.$vuex.getters.language
              ),
            },
          ]
        : [
            {
              name: this.$t('LINK_TERMS'),
              to: 'terms-conditions',
            },
            {
              name: this.$t('LINK_WITHDRAWAL'),
              to: 'right-of-withdrawal',
            },
            {
              name: this.$t('LINK_PRIVACY'),
              to: 'privacy-policy',
            },
            {
              name: this.$t('LINK_COOKIE_SETTINGS'),
              to: () => {
                if (window.Cookiebot) window.Cookiebot.show()
              },
            },
            {
              name: this.$t('LINK_IMPRINT'),
              to: 'imprint',
            },
            {
              name: this.$t('LINK_CONTACT'),
              to: getContactLink(
                this.$vuex.getters.region,
                this.$vuex.getters.language
              ),
            },
          ]
    },
    companyLinks(): FooterLink[] {
      return [
        {
          name: this.$t('LINK_COMPANY_HISTORY'),
          to: 'history',
        },
        {
          name: this.$t('LINK_COMPANY_FACTS'),
          to: 'facts',
        },
        {
          name: this.$t('LINK_TRAINER'),
          to: 'trainer',
        },
        {
          name: this.$t('LINK_AFFILIATE'),
          to: {
            name: 'landing-slug',
            params: { uid: 'df992c9e-27cd-47be-aed0-27532be6efc0' },
          },
        },
      ]
    },
    productLinks(): FooterLink[] {
      return appConfig.shopCollections
        .filter(({ unlisted }) => !unlisted)
        .map(({ handle }) => ({
          name: this.$t(
            `LINK_SHOP_${handle.replaceAll('-', '_').toUpperCase()}`
          ),
          to: { name: 'shop', hash: `#list-${handle}` },
        }))
    },
    serviceLinks(): FooterLink[] {
      return [
        {
          name: this.$t('LINK_CONSULTING'),
          to: 'consulting',
        },
        {
          name: this.$t('LINK_SHIPPING'),
          to: 'shipping',
        },
        {
          name: this.$t('LINK_RETURNS'),
          to: 'returns',
        },
        {
          name: this.$t('LINK_SUPPORT'),
          to: getI18nSupportLink(
            this.$vuex.getters.region,
            this.$vuex.getters.language
          ),
        },
      ].filter(
        (link) =>
          !appConfig.urlIsoBlacklist.some(
            ({ page, iso }) =>
              link.to === page.replace(/\/index$/, '') &&
              slugToIso(this.$i18n.locale) === iso
          )
      )
    },
    socialLinks(): SocialLink[] {
      const networks = this.$vuex.getters.socials
      return Object.keys(networks).map((network) => {
        return {
          name: `${network.charAt(0).toUpperCase()}${network.slice(1)}`,
          icon: `social-${network}`,
          to: networks[network],
        }
      })
    },
  },
})
