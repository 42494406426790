





































































import Vue, { PropType } from 'vue'
import BSGIcon from './Icon.vue'
import { enumValidator } from '~/services/enum-props'

export type Button = {
  to: string | object | undefined | 'submit'
  text?: string
  icon?: string
  title?: string
  colorTheme?: string
  disabled?: boolean
  iconPosition?: string
  look?: string
  size?: string
  target?: string
  trackingId?: string
}

export default Vue.extend({
  name: 'BSGButton',
  components: { BSGIcon },
  props: {
    /**
     * describes the target action, use
     * - a string or object (NuxtLink) for internal link
     * - an url beginning with "http" for external link
     * - the string "submit" for the submit button in forms
     * - a tel: link for phone calls
     */
    to: {
      type: [String, Object, undefined, 'submit'] as PropType<
        string | object | undefined | 'submit'
      >,
      default: undefined,
    },
    title: {
      type: String as PropType<string>,
      default: '',
    },
    colorTheme: {
      type: String as PropType<string>,
      default: 'light',
      validator: enumValidator(['light', 'dark']),
    },
    /* option is only relevant for type "function" */
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    icon: {
      type: String as PropType<string>,
      default: 'information',
    },
    iconPosition: {
      type: String as PropType<string>,
      default: 'no-icon',
      validator: enumValidator(['no-icon', 'left', 'right']),
    },
    look: {
      type: String as PropType<string>,
      default: 'primary',
      validator: enumValidator(['primary', 'outline', 'plain', 'icon']),
    },
    size: {
      type: String as PropType<string>,
      default: 'default',
      validator: enumValidator(['default', 'large']),
    },
    text: {
      type: String as PropType<string>,
      default: '',
    },
    target: {
      type: [String] as PropType<string | undefined>,
      default: undefined,
      validator: undefined || enumValidator(['_self', '_blank']),
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    trackingId: {
      type: String as PropType<string>,
      default: '',
    },
  },
  data() {
    return {
      isMounted: false,
    }
  },
  computed: {
    iconColor(): string {
      if (this.colorTheme === 'light') {
        if (this.look === 'primary' && this.disabled) return 'neutral-300'
        if (this.look === 'primary') return 'neutral-000'
        return 'neutral-800'
      } else {
        if (this.look === 'primary') return 'neutral-800'
        return 'neutral-000'
      }
    },
    type(): 'internal' | 'external' | 'submit' | 'tel' {
      if (this.to === 'submit') {
        return 'submit'
      }
      if (typeof this.to === 'string' && this.to.startsWith('http')) {
        return 'external'
      }
      if (typeof this.to === 'string' && this.to.startsWith('tel:')) {
        return 'tel'
      }
      return 'internal'
    },
    aimless() {
      return !(
        (this.$listeners && this.$listeners.click) ||
        this.to !== undefined
      )
    },
  },
  mounted() {
    this.isMounted = true

    if (this.look === 'icon' && !this.title)
      // eslint-disable-next-line no-console
      console.warn(
        `BSGButton with look "icon" should have alternative "title" ${this.icon}`
      )
  },
  methods: {
    callFunction(event: MouseEvent) {
      if (this.type !== 'external' && this.type !== 'submit' && this.type !== 'tel')
        event.preventDefault()
      /* istanbul ignore next */
      if (this.disabled) return
      if (this.loading) return
      if (this.type === 'internal' && this.isMounted && this.to)
        this.$router.push(this.createInternalLink(this.to))
      this.$emit('click')
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createInternalLink(to: any): any {
      // is $i18n library available
      if (this.$i18n && this.$routerI18n) {
        return this.$routerI18n.localePath(to)
      } else {
        return to
      }
    },
  },
})
