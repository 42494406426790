import { render, staticRenderFns } from "./error.vue?vue&type=template&id=22afb8a2&scoped=true&"
import script from "./error.vue?vue&type=script&lang=ts&"
export * from "./error.vue?vue&type=script&lang=ts&"
import style0 from "./error.vue?vue&type=style&index=0&id=22afb8a2&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22afb8a2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BSGIllustration: require('/workspace/styleguide/components/Illustration.vue').default,BSGButton: require('/workspace/styleguide/components/Button.vue').default,BSGButtonGroup: require('/workspace/styleguide/components/ButtonGroup.vue').default})
