

















import Vue, { PropType } from 'vue'
import { enumValidator } from '~/services/enum-props'
import { getColorNames, getColorStyle } from '~/styleguide/utils'

export const looks = {
  none: '',
  '1': 'head-400 md:head-500 xl:head-600',
  '2': 'head-300 md:head-400 xl:head-500',
  '3': 'head-200 md:head-300 xl:head-400',
  '4': 'head-100 md:head-200 xl:head-300',
  '5': 'text-600-bold md:head-100 xl:head-200',
  '6': 'text-500-bold md:text-600-bold xl:head-100',
} as Record<string, string>

export default Vue.extend({
  name: 'BSGHeadline',
  props: {
    level: {
      type: String as PropType<string>,
      default: '1',
      validator: enumValidator(['1', '2', '3', '4', '5', '6']),
    },
    look: {
      type: String as PropType<string>,
      default: '1',
      validator: enumValidator(Object.keys(looks)),
    },
    centered: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    color: {
      type: String as PropType<string>,
      default: 'neutral-800',
      validator: enumValidator(getColorNames()),
    },
    overline: {
      type: String as PropType<string>,
      default: '',
    },
  },
  computed: {
    /* Use color value to prevent purging tailwind class */
    colorStyle(): Partial<CSSStyleDeclaration> {
      return getColorStyle(this.color)
    },
    lookClass(): string {
      return looks[this.look]
    },
  },
  mounted() {
    // eslint-disable-next-line no-console
    if (!this.$slots.default) console.error('Default slot is required')
  },
})
