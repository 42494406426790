import { Plugin } from '@nuxt/types'
import Vue from 'vue'
import { zendeskInit, zendeskSetLocale } from '~/services/zendesk'

type Zendesk = { isAvailable: boolean }

declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Vue {
    $zendesk: Zendesk
  }
}

declare module '@nuxt/types' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface NuxtAppOptions {
    $zendesk: Zendesk
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Context {
    $zendesk: Zendesk
  }
}

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    // Can be used by services like GTM to control whether Zendesk should be made available on mount
    isCookiebotOpen?: boolean
    zendeskLoadReady?: boolean
  }
}

const zendesk: Plugin = ({ store }, inject) => {
  const obs = Vue.observable({ isAvailable: false })
  if (process.client) {
    const initialize = () => {
      zendeskInit()
      zendeskSetLocale(store.state.i18n.locale)
      obs.isAvailable = true
    }
    window.addEventListener(
      'mountLayout',
      () => (window.zendeskLoadReady = true)
    )
    if (window.zE) initialize()
    else window.addEventListener('zendeskLoaded', initialize)
  }
  inject('zendesk', obs)
}

export default zendesk
