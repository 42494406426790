import createMultiTabState from 'vuex-multi-tab-state'


// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default ({ store }) => {
  createMultiTabState({
    statesPaths: [
      'user.totalTrainings',
      'user.finishedTrainings',
      'user.activePlanWorkouts',
    ],
  })(store)
}
