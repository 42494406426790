



































import Vue from 'vue'

export default Vue.extend({
  name: 'CommonVideoNavbar',
  data() {
    return {
      isNegative: false,
    }
  },
  watch: {
    '$route.name': {
      handler() {
        this.isVideoDetailPageOnDesktop()
      },
    },
  },
  mounted() {
    this.isVideoDetailPageOnDesktop()
  },
  methods: {
    isVideoDetailPageOnDesktop() {
      if (this.$route.name?.startsWith('video-detail')) {
        this.isNegative = true
      } else {
        this.isNegative = false
      }
    },
  },
})
