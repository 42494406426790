import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '~/store'
import config, {
  ConfiguratorAssembly,
  ConfiguratorBar,
  ConfiguratorBungeeColors,
  ConfiguratorBungeeStrength,
  ConfiguratorColor,
  ConfiguratorConfiguration,
  ConfiguratorFrame,
  ConfiguratorLegs,
  ConfiguratorMat,
  ConfiguratorSize
} from "~/components/website/configurator/config";
import { isConfigValid } from '~/components/website/configurator/services'
import { generateUUID } from "three/src/math/MathUtils";
import { v4 as uuidv4 } from "uuid";

const DEFAULT_CONFIG = (): ConfiguratorConfiguration => config.presets.default

export const state = DEFAULT_CONFIG

export type ConfiguratorState = ReturnType<typeof state>

export const getters: GetterTree<ConfiguratorState, RootState> = {
  canSetSize(state) {
    return (size: ConfiguratorSize) => {
      return isConfigValid({ ...state, size })
    }
  },
  canSetFrame(state) {
    return (frame: ConfiguratorFrame,  region = null) => {
      return isConfigValid({ ...state, frame, region })
    }
  },
  canSetLegs(state, region = null) {
    return (legs: ConfiguratorLegs, region: string) => {
        return isConfigValid({ ...state, legs, region })
    }
  },
  canSetBar(state) {
    return (bar: ConfiguratorBar) => {
      return isConfigValid({ ...state, bar })
    }
  },
  canSetMat(state) {
    return (mat: ConfiguratorMat) => {
      return isConfigValid({ ...state, mat })
    }
  },
  canSetBungeeStrength(state) {
    return (bungeeStrength: ConfiguratorBungeeStrength) => {
      return isConfigValid({ ...state, bungeeStrength })
    }
  },
}

export const mutations: MutationTree<ConfiguratorState> = {
  RESET: (state) => {
    Object.assign(state, DEFAULT_CONFIG())
  },
  CHANGE_SIZE: (state, size: ConfiguratorSize) => {
    state.size = size
  },
  CHANGE_FRAME: (state, frame: ConfiguratorFrame) => {
    state.frame = frame
  },
  CHANGE_LEGS: (state, legs: ConfiguratorLegs) => {
    state.legs = legs
  },
  CHANGE_BAR: (state, bar: ConfiguratorBar) => {
    state.bar = bar
  },
  CHANGE_MAT: (state, mat: ConfiguratorMat) => {
    state.mat = mat
  },
  CHANGE_MAT_COLOR: (state, matColor: ConfiguratorColor) => {
    state.matColor = matColor
  },
  CHANGE_BUNGEE_STRENGTH: (
    state,
    bungeeStrength: ConfiguratorBungeeStrength
  ) => {
    state.bungeeStrength = bungeeStrength
  },
  ADD_BUNGEE_COLOR: (state, bungeeColor: ConfiguratorColor) => {
    state.bungeeColors.push(bungeeColor)
    state.bungeeColors = state.bungeeColors.sort()
  },
  REMOVE_BUNGEE_COLOR: (state, bungeeColor: ConfiguratorColor) => {
    state.bungeeColors.splice(state.bungeeColors.indexOf(bungeeColor), 1)
  },
  CHANGE_BUNGEE_COLORS: (state, bungeeColors: ConfiguratorBungeeColors) => {
    state.bungeeColors = bungeeColors
  },
  CHANGE_ASSEMBLY: (state, assembly: ConfiguratorAssembly) => {
    state.assembly = assembly
    if( assembly === 'yes') {
      if(state.__uniqueId === ''){
        state.__uniqueId = uuidv4()
      }
    }
  },
  CHANGE_UNIQUEID: (state, __uniqueId : string) => {
    state.__uniqueId = __uniqueId
  },
}

export const actions: ActionTree<ConfiguratorState, RootState> = {
  setSize({ state, commit }, size: ConfiguratorSize) {
    if (isConfigValid({ ...state, size })) commit('CHANGE_SIZE', size)
  },
  setFrame({ state, commit }, frame: ConfiguratorFrame) {
    if (isConfigValid({ ...state, frame })) commit('CHANGE_FRAME', frame)
  },
  setLegs({ state, commit }, legs: ConfiguratorLegs) {
    if (isConfigValid({ ...state, legs })) commit('CHANGE_LEGS', legs)
  },
  setBar({ state, commit }, bar: ConfiguratorBar) {
    if (isConfigValid({ ...state, bar })) commit('CHANGE_BAR', bar)
  },
  setMat({ state, commit }, mat: ConfiguratorMat) {
    if (isConfigValid({ ...state, mat })) commit('CHANGE_MAT', mat)
  },
  setMatColor({ commit }, matColor: ConfiguratorColor) {
    commit('CHANGE_MAT_COLOR', matColor)
  },
  setBungeeStrength(
    { state, commit },
    bungeeStrength: ConfiguratorBungeeStrength
  ) {
    if (isConfigValid({ ...state, bungeeStrength }))
      commit('CHANGE_BUNGEE_STRENGTH', bungeeStrength)
  },
  setBungeeColors({ commit }, bungeeColors: ConfiguratorBungeeColors) {
    commit('CHANGE_BUNGEE_COLORS', bungeeColors)
  },
  toggleBungeeColor({ state, commit }, bungeeColor: ConfiguratorColor) {
    if (
      state.bungeeColors.includes(bungeeColor) &&
      state.bungeeColors.length > 1
    )
      commit('REMOVE_BUNGEE_COLOR', bungeeColor)
    else if (
      !state.bungeeColors.includes(bungeeColor) &&
      state.bungeeColors.length < 3
    )
      commit('ADD_BUNGEE_COLOR', bungeeColor)
  },
  setAssembly({ state, commit }, assembly: ConfiguratorAssembly) {
    if (isConfigValid({ ...state, assembly })) commit('CHANGE_ASSEMBLY', assembly)
  },
  setConfiguration({ commit }, config: ConfiguratorConfiguration) {
    if (isConfigValid(config)) {
      commit('CHANGE_SIZE', config.size)
      commit('CHANGE_FRAME', config.frame)
      commit('CHANGE_LEGS', config.legs)
      commit('CHANGE_BAR', config.bar)
      commit('CHANGE_MAT', config.mat)
      commit('CHANGE_BUNGEE_STRENGTH', config.bungeeStrength)
      commit('CHANGE_MAT_COLOR', config.matColor)
      commit('CHANGE_BUNGEE_COLORS', config.bungeeColors)
      commit('CHANGE_UNIQUEID', config.__uniqueId)
      commit('CHANGE_ASSEMBLY', config.assembly)

    }
  },
  resetConfiguration({ commit }) {
    commit('RESET')
  },
}
