





















import Vue, { PropType } from 'vue'
import { enumValidator } from '~/services/enum-props'
import { getColorNames, getColorStyle } from '~/styleguide/utils'

export const minSize = 8
export const maxSize = 100
export const IgnoreColor = ['payment', 'auth']

export default Vue.extend({
  name: 'BSGIcon',
  props: {
    name: {
      type: String as PropType<string>,
      required: true,
    },
    title: {
      type: String as PropType<string>,
      default: '',
    },
    size: {
      type: Number as PropType<number>,
      default: 24,
      validator: (v) => v >= minSize && v <= maxSize,
    },
    color: {
      type: String as PropType<string>,
      default: 'neutral-800',
      validator: enumValidator(getColorNames()),
    },
  },
  data() {
    return {
      icon: undefined as undefined | Promise<unknown>,
    }
  },
  computed: {
    /**
     * Set the color which impacts fill and stroke color of icon
     */
    colorStyle(): Partial<CSSStyleDeclaration> {
      return getColorStyle(this.color)
    },
    isColored(): boolean {
      let regex: RegExp
      try {
        regex = new RegExp(`(${IgnoreColor.join('|')})`, 'gs')
      } catch {
        regex = new RegExp(`(${IgnoreColor.join('|')})`, 'g')
      }
      const matches = this.name.match(regex)
      return matches !== null && matches.length > 0
    },
  },
  watch: {
    name: {
      immediate: true,
      async handler(name: string) {
        try {
          const { default: icon } = await import(
            '~/assets/svg/icons/' + name + '.svg?inline'
          )
          this.icon = icon
        } catch {
          // Icon import failed
        }
      },
    },
  },
})
